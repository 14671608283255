import styled, { keyframes } from "styled-components";
import backgroundLayout from "../../asset/backgroundLayout.svg";
import "../../App.css";
import "../../asset/fonts/grotasansaltrd-heavy.otf";

// Définir l'animation @keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const BackgroundDiv = styled.div`
  background-image: url("${backgroundLayout}");
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  padding: 10px;
  display: flex;
  justify-content: center;
`;

export const BackgroundAlign = styled.div`
  width: 100%;
  height: 100%;
`;

export const InputsWrapper = styled.div`
  width: ${(props) => (props.sizeWidth === "tabletOrMobile" ? "90%" : "100%")};
  height: ${(props) => (props.sizeWidth === "tabletOrMobile" ? "70%" : "90%")};
  align-self: center;
  background: #144153b5 0% 0% no-repeat padding-box;
  box-shadow: 7px 7px 10px #00000029;
  border-radius: 29px;

  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  overflow-y: scroll;
  justify-content: space-around;
`;

export const TitleWaykeup = styled.div`
  // font-weight: bold;
  font-style: italic;
  // font-family: GrotaSansAltRd;
  font-size: ${(props) =>
    props.sizeWidth === "tabletOrMobile" ? "40px" : "45px"};
  margin: 10px 0 10px 0;
`;

export const ImgLandingComputer = styled.img`
  width: auto;
  height: 70vh;
  margin: 10px 0px;
  align-self: center;
  width: 100%;
  object-fit: contain;

  animation: ${fadeIn} 1.5s ease-in-out;
`;
export const ImgLandingPhone = styled.img`
  width: 100%;
  height: auto;
  margin: 10px 0px;
  align-self: center;
  object-fit: cover;
  animation: ${fadeIn} 1.5s ease-in-out;
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
