import styled from "styled-components";

export const WrapperLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
`;

export const AdminLayout = styled.div`
  display: flex;
`;

export const ActivationCheckBox = styled.div`
  display: flex;
  align-items: center;
`;
