export const phrasesTalentIntMul = [
  {
    text: "Utiliser des rythmes pour mémoriser des informations.",
    scores:{
    musicale: 0.9,
    intrapersonnel: 0.2,
    interpersonnel: 0.2,
    naturaliste: 0.1,
    spaciale: 0.1,
    kinesthésique: 0.3,
    linguistique: 0.5,
    logique: 0.1,},
  },
  {
    text: "Bouger souvent pour se concentrer.",
    scores:{
    musicale: 0.4,
    intrapersonnel: 0.2,
    interpersonnel: 0.2,
    naturaliste: 0.1,
    spaciale: 0.4,
    kinesthésique: 0.5,
    linguistique: 0.3,
    logique: 0.1,},
  },
  {
    text: "Ecrire et créer pour exprimer des idées.",
    scores:{
    musicale: 0.5,
    intrapersonnel: 0.7,
    interpersonnel: 0.3,
    naturaliste: 0.2,
    spaciale: 0.2,
    kinesthésique: 0.2,
    linguistique: 0.6,
    logique: 0.4,},
  },
  {
    text: "Travailler seul pour mieux se concentrer.",
    scores:{
    musicale: 0.3,
    intrapersonnel: 0.7,
    interpersonnel: 0.3,
    naturaliste: 0.2,
    spaciale: 0.2,
    kinesthésique: 0.2,
    linguistique: 0.3,
    logique: 0.4,},
  },
  {
    text: "Réfléchir avant de prendre des décisions importantes.",
    scores:{
    musicale: 0.2,
    intrapersonnel: 0.8,
    interpersonnel: 0.2,
    naturaliste: 0.5,
    spaciale: 0.2,
    kinesthésique: 0.2,
    linguistique: 0.3,
    logique: 0.6,},
  },
  {
    text: "Fixer des objectifs personnels pour se motiver.",
    scores:{
    musicale: 0.2,
    intrapersonnel: 0.8,
    interpersonnel: 0.2,
    naturaliste: 0.5,
    spaciale: 0.2,
    kinesthésique: 0.2,
    linguistique: 0.5,
    logique: 0.7,},
  },
  {
    text: "Travailler en équipe pour atteindre des objectifs communs.",
    scores:{
    musicale: 0.2,
    intrapersonnel: 0.2,
    interpersonnel: 0.8,
    naturaliste: 0.2,
    spaciale: 0.2,
    kinesthésique: 0.2,
    linguistique: 0.5,
    logique: 0.7,},
  },
  {
    text: "Comprendre intuitivement les émotions et les motivations des autres.",
    scores:{
    musicale: 0.2,
    intrapersonnel: 0.2,
    interpersonnel: 0.9,
    naturaliste: 0.5,
    spaciale: 0.2,
    kinesthésique: 0.2,
    linguistique: 0.5,
    logique: 0.7,},
  },
  {
    text: "Utiliser la communication verbale et non verbale pour convaincre les autres.",
    scores:{
    musicale: 0.1,
    intrapersonnel: 0.1,
    interpersonnel: 0.8,
    naturaliste: 0.5,
    spaciale: 0.3,
    kinesthésique: 0.6,
    linguistique: 0.6,
    logique: 0.3,},
  },
  {
    text: "Observer et analyser les schémas et les relations dans le groupe.",
    scores:{
    musicale: 0.1,
    intrapersonnel: 0.3,
    interpersonnel: 0.4,
    naturaliste: 0.8,
    spaciale: 0.1,
    kinesthésique: 0.1,
    linguistique: 0.3,
    logique: 0.5,},
  },
  {
    text: "S'intéresser aux autres et à leur comportement.",
    scores:{
    musicale: 0.1,
    intrapersonnel: 0.3,
    interpersonnel: 0.6,
    naturaliste: 0.7,
    spaciale: 0.1,
    kinesthésique: 0.2,
    linguistique: 0.4,
    logique: 0.6,},
  },
  {
    text: "Utiliser des images mentales pour résoudre des problèmes complexes.",
    scores:{
    musicale: 0.1,
    intrapersonnel: 0.5,
    interpersonnel: 0.2,
    naturaliste: 0.2,
    spaciale: 0.6,
    kinesthésique: 0.1,
    linguistique: 0.2,
    logique: 0.8,},
  },
  {
    text: "Créer des modèles visuels détaillés pour représenter des concepts abstraits.",
    scores:{
    musicale: 0.1,
    intrapersonnel: 0.4,
    interpersonnel: 0.2,
    naturaliste: 0.3,
    spaciale: 0.7,
    kinesthésique: 0.1,
    linguistique: 0.2,
    logique: 0.9,},
  },
  {
    text: "Lire des visuels et naviguer dans des environnements complexes.",
    scores:{
    musicale: 0.1,
    intrapersonnel: 0.2,
    interpersonnel: 0.2,
    naturaliste: 0.3,
    spaciale: 0.9,
    kinesthésique: 0.1,
    linguistique: 0.2,
    logique: 0.7,},
  },
  {
    text: "Apprendre à travers des activités physiques et pratiques.",
    scores:{
    musicale: 0.1,
    intrapersonnel: 0.2,
    interpersonnel: 0.2,
    naturaliste: 0.3,
    spaciale: 0.4,
    kinesthésique: 0.8,
    linguistique: 0.2,
    logique: 0.3,},
  },
  {
    text: "Utiliser le mouvement pour exprimer des idées et des émotions.",
    scores:{
    musicale: 0.4,
    intrapersonnel: 0.3,
    interpersonnel: 0.4,
    naturaliste: 0.1,
    spaciale: 0.4,
    kinesthésique: 0.9,
    linguistique: 0.2,
    logique: 0.1,},
  },
  {
    text: "Produire des rendus manuels avec précision.",
    scores:{
    musicale: 0.3,
    intrapersonnel: 0.3,
    interpersonnel: 0.4,
    naturaliste: 0.1,
    spaciale: 0.4,
    kinesthésique: 0.9,
    linguistique: 0.2,
    logique: 0.1,},
  },
  {
    text: "S'exprimer clairement et de manière persuasive à l'écrit et à l'oral.",
    scores:{
    musicale: 0.3,
    intrapersonnel: 0.2,
    interpersonnel: 0.6,
    naturaliste: 0.1,
    spaciale: 0.1,
    kinesthésique: 0.2,
    linguistique: 0.9,
    logique: 0.2,},
  },
  {
    text: "Utiliser un vocabulaire étendu pour communiquer des idées complexes.",
    scores:{
    musicale: 0.1,
    intrapersonnel: 0.2,
    interpersonnel: 0.3,
    naturaliste: 0.2,
    spaciale: 0.1,
    kinesthésique: 0.1,
    linguistique: 0.8,
    logique: 0.7,},
  },
  {
    text: "Apprendre à travers la lecture et l'écriture.",
    scores:{
    musicale: 0.1,
    intrapersonnel: 0.5,
    interpersonnel: 0.1,
    naturaliste: 0.5,
    spaciale: 0.1,
    kinesthésique: 0.2,
    linguistique: 0.9,
    logique: 0.8,},
  }
];




export const phrasesCommunicationIntMul = [
  {
    text: "Communiquer à travers des références artistiques",
    scores: {
      musicale: 0.9,
      intrapersonnel: 0.2,
      interpersonnel: 0.2,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.3,
      linguistique: 0.5,
      logique: 0.1
    }
  },
  {
    text: "Utiliser des éléments créatifs pour exprimer des idées complexes.",
    scores: {
      musicale: 0.4,
      intrapersonnel: 0.2,
      interpersonnel: 0.2,
      naturaliste: 0.1,
      spaciale: 0.4,
      kinesthésique: 0.5,
      linguistique: 0.3,
      logique: 0.1
    }
  },
  {
    text: "Collaborer avec d'autres pour créer une harmonie de groupe.",
    scores: {
      musicale: 0.8,
      intrapersonnel: 0.7,
      interpersonnel: 0.3,
      naturaliste: 0.2,
      spaciale: 0.2,
      kinesthésique: 0.2,
      linguistique: 0.6,
      logique: 0.4
    }
  },
  {
    text: "Exprimer ses pensées et ses émotions à travers des espaces personnels.",
    scores: {
      musicale: 0.3,
      intrapersonnel: 0.7,
      interpersonnel: 0.3,
      naturaliste: 0.2,
      spaciale: 0.2,
      kinesthésique: 0.2,
      linguistique: 0.3,
      logique: 0.4
    }
  },
  {
    text: "Organiser des temps seul pour prendre du recul.",
    scores: {
      musicale: 0.2,
      intrapersonnel: 0.8,
      interpersonnel: 0.2,
      naturaliste: 0.5,
      spaciale: 0.2,
      kinesthésique: 0.2,
      linguistique: 0.3,
      logique: 0.6
    }
  },
  {
    text: "Favoriser l'introspection pour résoudre des conflits.",
    scores: {
      musicale: 0.2,
      intrapersonnel: 0.8,
      interpersonnel: 0.2,
      naturaliste: 0.5,
      spaciale: 0.2,
      kinesthésique: 0.2,
      linguistique: 0.2,
      logique: 0.6
    }
  },
  {
    text: "Écouter activement les autres pour comprendre leurs besoins et leurs émotions.",
    scores: {
      musicale: 0.2,
      intrapersonnel: 0.2,
      interpersonnel: 0.9,
      naturaliste: 0.6,
      spaciale: 0.2,
      kinesthésique: 0.2,
      linguistique: 0.5,
      logique: 0.4
    }
  },
  {
    text: "Communiquer pour résoudre les conflits de façon pacifique.",
    scores: {
      musicale: 0.2,
      intrapersonnel: 0.2,
      interpersonnel: 0.9,
      naturaliste: 0.5,
      spaciale: 0.2,
      kinesthésique: 0.2,
      linguistique: 0.5,
      logique: 0.3
    }
  },
  {
    text: "Animer des discussions de groupe pour encourager la participation de tous.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.8,
      naturaliste: 0.3,
      spaciale: 0.3,
      kinesthésique: 0.2,
      linguistique: 0.2,
      logique: 0.3
    }
  },
  {
    text: "Partager des connaissances pour sensibiliser les autres.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.3,
      interpersonnel: 0.4,
      naturaliste: 0.8,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.3,
      logique: 0.5
    }
  },
  {
    text: "Organiser des sorties pour étudier le terrain.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.3,
      interpersonnel: 0.6,
      naturaliste: 0.8,
      spaciale: 0.1,
      kinesthésique: 0.2,
      linguistique: 0.4,
      logique: 0.6
    }
  },
  {
    text: "Enseigner des méthodes pour promouvoir l'autonomie.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.5,
      interpersonnel: 0.2,
      naturaliste: 0.7,
      spaciale: 0.6,
      kinesthésique: 0.1,
      linguistique: 0.2,
      logique: 0.8
    }
  },
  {
    text: "Utiliser des présentations visuelles pour expliquer des concepts complexes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.4,
      interpersonnel: 0.2,
      naturaliste: 0.3,
      spaciale: 0.7,
      kinesthésique: 0.1,
      linguistique: 0.2,
      logique: 0.9
    }
  },
  {
    text: "Créer des visuels pour inspirer et provoquer la réflexion.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.2,
      interpersonnel: 0.2,
      naturaliste: 0.3,
      spaciale: 0.9,
      kinesthésique: 0.1,
      linguistique: 0.2,
      logique: 0.7
    }
  },
  {
    text: "Utiliser des outils de modélisation pour concevoir des prototypes réalistes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.2,
      interpersonnel: 0.2,
      naturaliste: 0.3,
      spaciale: 0.8,
      kinesthésique: 0.5,
      linguistique: 0.2,
      logique: 0.3
    }
  },
  {
    text: "Encourager l'organisation physique et sportive.",
    scores: {
      musicale: 0.4,
      intrapersonnel: 0.4,
      interpersonnel: 0.2,
      naturaliste: 0.1,
      spaciale: 0.4,
      kinesthésique: 0.9,
      linguistique: 0.2,
      logique: 0.1
    }
  },
  {
    text: "Favoriser des éléments corporels pour exprimer des émotions à travers le mouvement.",
    scores: {
      musicale: 0.3,
      intrapersonnel: 0.3,
      interpersonnel: 0.4,
      naturaliste: 0.1,
      spaciale: 0.4,
      kinesthésique: 0.9,
      linguistique: 0.2,
      logique: 0.1
    }
  },
  {
    text: "Utiliser ses compétences manuelles pour créer des solutions fonctionnelles et esthétiques.",
    scores: {
      musicale: 0.3,
      intrapersonnel: 0.2,
      interpersonnel: 0.3,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.9,
      linguistique: 0.1,
      logique: 0.2
    }
  },
  {
    text: "Communiquer clairement des idées à travers l'écriture.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.2,
      interpersonnel: 0.3,
      naturaliste: 0.2,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.8,
      logique: 0.6
    }
  },
  {
    text: "Participer à des débats pour exprimer et défendre son point de vue.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.2,
      naturaliste: 0.2,
      spaciale: 0.1,
      kinesthésique: 0.4,
      linguistique: 0.8,
      logique: 0.6
    }
  },
];

export const phrasesMotivationIntMul = [
  
    {
      text: "Créer et apprécier des propositions uniques.",
      scores: {
        musicale: 0.9,
        intrapersonnel: 0.2,
        interpersonnel: 0.2,
        naturaliste: 0.1,
        spaciale: 0.1,
        kinesthésique: 0.3,
        linguistique: 0.5,
        logique: 0.1
      }
    },
    {
      text: "Participer à de nouveaux projets inspirants.",
      scores: {
        musicale: 0.7,
        intrapersonnel: 0.2,
        interpersonnel: 0.2,
        naturaliste: 0.1,
        spaciale: 0.4,
        kinesthésique: 0.5,
        linguistique: 0.3,
        logique: 0.1
      }
    },
    {
      text: "Explorer différents environnements pour enrichir sa compréhension du domaine.",
      scores: {
        musicale: 0.5,
        intrapersonnel: 0.7,
        interpersonnel: 0.3,
        naturaliste: 0.8,
        spaciale: 0.2,
        kinesthésique: 0.2,
        linguistique: 0.2,
        logique: 0.4
      }
    },
    {
      text: "Désir mieux se connaître et progresser.",
      scores: {
        musicale: 0.3,
        intrapersonnel: 0.9,
        interpersonnel: 0.3,
        naturaliste: 0.2,
        spaciale: 0.2,
        kinesthésique: 0.2,
        linguistique: 0.3,
        logique: 0.4
      }
    },
    {
      text: "Essayer de dépasser ses limites personnelles et à s'améliorer constamment.",
      scores: {
        musicale: 0.2,
        intrapersonnel: 0.8,
        interpersonnel: 0.2,
        naturaliste: 0.5,
        spaciale: 0.2,
        kinesthésique: 0.2,
        linguistique: 0.3,
        logique: 0.6
      }
    },
    {
      text: "Désir d'aider les autres à atteindre leurs objectifs et à résoudre leurs problèmes.",
      scores: {
        musicale: 0.1,
        intrapersonnel: 0.2,
        interpersonnel: 0.8,
        naturaliste: 0.6,
        spaciale: 0.2,
        kinesthésique: 0.2,
        linguistique: 0.3,
        logique: 0.4
      }
    },
    {
      text: "Contribuer à une atmosphère de coopération et de compréhension mutuelle.",
      scores: {
        musicale: 0.2,
        intrapersonnel: 0.2,
        interpersonnel: 0.9,
        naturaliste: 0.4,
        spaciale: 0.2,
        kinesthésique: 0.2,
        linguistique: 0.2,
        logique: 0.3
      }
    },
    {
      text: "Est stimulé par les interactions sociales enrichissantes et les relations significatives avec autrui.",
      scores: {
        musicale: 0.1,
        intrapersonnel: 0.1,
        interpersonnel: 0.8,
        naturaliste: 0.3,
        spaciale: 0.3,
        kinesthésique: 0.6,
        linguistique: 0.6,
        logique: 0.3
      }
    },
    {
      text: "Explorer et protéger l'environnement.",
      scores: {
        musicale: 0.1,
        intrapersonnel: 0.3,
        interpersonnel: 0.4,
        naturaliste: 0.9,
        spaciale: 0.1,
        kinesthésique: 0.1,
        linguistique: 0.3,
        logique: 0.3
      }
    },
    {
      text: "Étudier la diversité des échanges et des environnements.",
      scores: {
        musicale: 0.1,
        intrapersonnel: 0.3,
        interpersonnel: 0.7,
        naturaliste: 0.9,
        spaciale: 0.1,
        kinesthésique: 0.2,
        linguistique: 0.4,
        logique: 0.6
      }
    },
    {
      text: "Créer des solutions visuellement captivantes et originales.",
      scores: {
        musicale: 0.1,
        intrapersonnel: 0.4,
        interpersonnel: 0.2,
        naturaliste: 0.3,
        spaciale: 0.9,
        kinesthésique: 0.1,
        linguistique: 0.2,
        logique: 0.6
      }
    },
    {
      text: "Utiliser des outils de design pour créer des concepts imaginatifs.",
      scores: {
        musicale: 0.1,
        intrapersonnel: 0.2,
        interpersonnel: 0.2,
        naturaliste: 0.3,
        spaciale: 0.8,
        kinesthésique: 0.1,
        linguistique: 0.2,
        logique: 0.7
      }
    },
    {
      text: "Valoriser le mouvement et les activités physiques.",
      scores: {
        musicale: 0.4,
        intrapersonnel: 0.3,
        interpersonnel: 0.2,
        naturaliste: 0.1,
        spaciale: 0.4,
        kinesthésique: 0.9,
        linguistique: 0.2,
        logique: 0.1
      }
    },
    {
      text: "Améliorer ses compétences créatives et manuelles.",
      scores: {
        musicale: 0.6,
        intrapersonnel: 0.3,
        interpersonnel: 0.2,
        naturaliste: 0.1,
        spaciale: 0.4,
        kinesthésique: 0.9,
        linguistique: 0.2,
        logique: 0.1
      }
    },
    {
      text: "Partager des idées et des connaissances à travers des mots et des discours.",
      scores: {
        musicale: 0.1,
        intrapersonnel: 0.2,
        interpersonnel: 0.3,
        naturaliste: 0.2,
        spaciale: 0.1,
        kinesthésique: 0.1,
        linguistique: 0.8,
        logique: 0.6
      }
    },
    {
      text: "Écrire et communiquer de manière précise et efficace.",
      scores: {
        musicale: 0.1,
        intrapersonnel: 0.1,
        interpersonnel: 0.1,
        naturaliste: 0.5,
        spaciale: 0.1,
        kinesthésique: 0.2,
        linguistique: 0.9,
        logique: 0.8
      }
    },
    {
      text: "Favoriser une réflexion critique et une analyse approfondie.",
      scores: {
        musicale: 0.1,
        intrapersonnel: 0.5,
        interpersonnel: 0.1,
        naturaliste: 0.3,
        spaciale: 0.1,
        kinesthésique: 0.2,
        linguistique: 0.5,
        logique: 0.8
      }
    },
    {
      text: "Valoriser les analyses logiques et une résolution de problèmes structurée.",
      scores: {
        musicale: 0.1,
        intrapersonnel: 0.6,
        interpersonnel: 0.1,
        naturaliste: 0.5,
        spaciale: 0.1,
        kinesthésique: 0.2,
        linguistique: 0.2,
        logique: 0.9
      }
    },
    {
      text: "Présenter des problèmes difficiles qui exigent logique et une analyse.",
      scores: {
        musicale: 0.1,
        intrapersonnel: 0.5,
        interpersonnel: 0.1,
        naturaliste: 0.4,
        spaciale: 0.1,
        kinesthésique: 0.2,
        linguistique: 0.2,
        logique: 0.8
      }
    }
  ]
;
