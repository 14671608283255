import { gql } from "@apollo/client";

export const GET_GROUP_BY_SCHOOL_ID = gql`
  query Query($schoolId: Int!) {
    getGroupBySchoolId(school_id: $schoolId) {
      id
      name
      school_id
      school {
        name
      }
      students {
        name
        email
        Module {
          resultValMot {
            id
            name
            e
            c
            util
            indiv
            s
            t
            comment
          }
          resultDisc {
            id
            name
            r
            y
            b
            g
            comment
          }
          resultIntMul {
            id
            name
            vl
            lm
            inter
            intra
            m
            k
            n
            s
            comment
          }
          modify_at
          id
        }
        lastname
        id
      }
      year
      size
       activatedModules
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query GetGroupById($getGroupByIdId: ID!) {
    getGroupById(id: $getGroupByIdId) {
      id
      size
      name
      school_id
      school {
        name
      }
      students {
        name
        email
        created_at
        profilVisible
        awaykerGroups {
          groupId
          userId
          user {
            id
          }
        }
        role {
          label
          id
        }
        roleId
        Module {
          resultValMot {
            id
            name
            e
            c
            util
            indiv
            s
            t
            comment
          }
          resultDisc {
            id
            name
            r
            y
            b
            g
            comment
          }
          resultIntMul {
            id
            name
            vl
            lm
            inter
            intra
            m
            k
            n
            s
            comment
          }
          modify_at
          id
        }
        lastname
        id
      }
      year
       activatedModules
    }
  }
`;

export const GET_GROUPS = gql`
  query GetGroupBySchoolId {
    getGroups {
      id
      name
      school_id
      school {
        name
      }
      students {
        name
        email
        created_at
        profilVisible
        Module {
          resultValMot {
            id
            name
            e
            c
            util
            indiv
            s
            t
            comment
          }
          resultDisc {
            id
            name
            r
            y
            b
            g
            comment
          }
          resultIntMul {
            id
            name
            vl
            lm
            inter
            intra
            m
            k
            n
            s
            comment
          }
          modify_at
          id
        }
        lastname
        id
      }
      year
      size
       activatedModules
    }
  }
`;
