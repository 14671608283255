import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  LayoutMenuDesktop,
  LayoutMenuPhone,
  LayoutMenuDesktopHeader,
  MenuElement,
  TitleMenuPhone,
  MiddleMenu,
} from "./SideMenu.style";

import { useApolloClient } from "@apollo/client";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ButtonProfil } from "../buttonProfil/ButtonProfil";
import { RoundNameUser } from "../buttonProfil/ButtonProfil.style";
import AppBar from "@mui/material/AppBar";
import { useToken } from "../../hooks/useToken";
import { GET_SCHOOL_BY_ID } from "../graphql/school/query";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "../graphql/user/query";
import { GET_GROUP_BY_ID } from "../graphql/group/query";
import { tokenise } from "../../utils/tokenise";

export const SideMenu = ({ propsDevice }) => {
  const location = useLocation();
  let navigate = useNavigate();
  const { removeToken } = useToken();

  const [values, setValues] = useState({
    name: "",
    surname: "",
    email: "",

    school: "",
    group: "",
    role: "",
  });
  const client = useApolloClient();

  const {
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    data: dataCurrentUser,
  } = useQuery(GET_CURRENT_USER);

  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    setCurrentUser(dataCurrentUser?.getCurrentUser);
  }, [dataCurrentUser]);

  const [schoolIdValue, setSchoolIdValue] = useState(currentUser?.school_id);
  const [groupIdValue, setGroupIdValue] = useState(currentUser?.groupId);
  useEffect(() => {
    if (currentUser) {
      setSchoolIdValue(currentUser?.school_id);
      setGroupIdValue(currentUser?.groupId);
    } else {
      setSchoolIdValue(1);
      setGroupIdValue(1);
    }
  }, [dataCurrentUser]);

  const {
    loading: loadingSchool,
    error: errorSchool,
    data: dataSchool,
  } = useQuery(GET_SCHOOL_BY_ID, {
    variables: {
      getSchoolByIdId: schoolIdValue,
    },
  });
  const {
    loading: loadingGroup,
    error: errorGroup,
    data: dataGroup,
  } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      getGroupByIdId: groupIdValue,
    },
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item) => {
    setAnchorEl(null);
    handleSelect(item);
  };

  const removeCurrentUser = async () => {
    await localStorage.removeItem("currentUser");
  };

  const handleClickSupport = () => {
    window.open(
      "https://airtable.com/appyHAfg6uubJhYo2/pagbuvNg3qlbXvTog/form",
      "_blank" // Spécifie l'ouverture dans un nouvel onglet
    );
  };

  const safeUrlFunction = (dynamicURL) => {
    const safeUrl = encodeURIComponent(dynamicURL);

    return safeUrl;
  };

  const handleSelect = (item) => {
    if (
      item === "Établissements 🏫 " ||
      "Mes classes " ||
      "Configurer mon profil 🎛️ 🎛️"
    ) {
      navigate("/workspace");
    }
    if (item === "Déconnexion 🚪") {
      removeToken();
      removeCurrentUser();
      client.clearStore();
      navigate("/login");
    }
    if (item === "Mon profil soft skills 🚀") {
      navigate(
        `/page/${safeUrlFunction(
          tokenise(
            `${currentUser?.id}/${values.group}/${dataGroup?.getGroupById?.name}/${currentUser?.id}/${values.name}`
          )
        )}`
      );
    }
    if (item === "Configurer mon profil 🎛️") {
      navigate(`/studentSingularitySetting/`);
    }
    if (item === "Contacter le support 💬") {
      handleClickSupport();
    }
  };
  useEffect(() => {
    setValues({
      ...values,
      name: currentUser?.name,
      surname: currentUser?.surname,
      email: currentUser?.email,
      school: dataSchool?.getSchoolById?.name || "",
      group: currentUser?.groupId,
      role:
        currentUser?.roleId === 1
          ? "ADMIN"
          : currentUser?.roleId === 2
            ? "TEACHER"
            : currentUser?.roleId === 4
              ? "AWAYKER"
              : "STUDENT",
    });
  }, [currentUser, dataSchool]);

  const menu = {
    ADMIN: [
      "Établissements 🏫",
      "Mon profil soft skills 🚀",
      "Configurer mon profil 🎛️",
      "Contacter le support 💬",
      "Déconnexion 🚪",
    ],
    TEACHER: [
      "Mes groupes 🍎",
      "Mon profil soft skills 🚀",
      "Configurer mon profil 🎛️",
      "Contacter le support 💬",
      "Déconnexion 🚪",
    ],
    AWAYKER: [
      "Établissements 🏫",
      "Mon profil soft skills 🚀",
      "Configurer mon profil 🎛️",
      "Contacter le support 💬",
      "Déconnexion 🚪",
    ],
    STUDENT: [
      "Configurer mon profil 🎛️",
      "Mon profil soft skills 🚀",
      "Contacter le support 💬",
      "Déconnexion 🚪",
    ],
  };

  return location.pathname !== "/login" &&
    location.pathname !== "/signup" &&
    location.pathname.split("/")[1] !== "shared" &&
    currentUser ? (
    <>
      {propsDevice.device == "tabletOrMobile" ? (
        <AppBar sx={{ backgroundColor: "#12516d" }}>
          <LayoutMenuPhone>
            {" "}
            <TitleMenuPhone>
              {values?.school ? values?.school : <></>}
            </TitleMenuPhone>
            <div style={{ display: "flex", alignItems: "center" }}>
              <RoundNameUser onClick={() => navigate(`/usersettings/`)}>
                {values?.name
                  ? values?.name[0]?.toUpperCase() +
                    values?.name[1]?.toUpperCase()
                  : "AW"}
              </RoundNameUser>
              <MenuIcon
                onClick={handleClick}
                sx={{
                  margin: "0 20px",
                  fontSize: "3rem",
                  color: "#FFF",
                  cursor: "pointer",
                }}
              />
            </div>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div>
                {Object.values(menu[values.role]).map((item) => {
                  return (
                    <MenuItem onClick={() => handleClose(item)}>
                      {item}
                    </MenuItem>
                  );
                })}
              </div>
            </Menu>
          </LayoutMenuPhone>
        </AppBar>
      ) : (
        <LayoutMenuDesktop>
          {" "}
          <LayoutMenuDesktopHeader smaller={values?.school?.length > 25}>
            <div style={{ margin: "0px 15px" }}>
              {values?.school ? values?.school : <></>}
            </div>
          </LayoutMenuDesktopHeader>
          <MiddleMenu>
            <div>
              {!values.role
                ? null
                : Object.values(menu[values?.role]).map((item) => {
                    return (
                      <MenuElement onClick={() => handleSelect(item)}>
                        {item}
                      </MenuElement>
                    );
                  })}
            </div>

            <ButtonProfil
              propsDevice={propsDevice.device}
              userName={
                values?.name ? (
                  values?.name
                ) : (
                  <LoadingButton loading>Patientez</LoadingButton>
                )
              }
              userId={currentUser?.id}
              userRole={
                values?.role ? (
                  values?.role
                ) : (
                  <LoadingButton loading>Patientez</LoadingButton>
                )
              }
            />
          </MiddleMenu>
        </LayoutMenuDesktop>
      )}
    </>
  ) : location.pathname == "/login" &&
    location.pathname.split("/")[1] == "signup" &&
    location.pathname.split("/")[1] == "shared" ? (
    <></>
  ) : (
    <></>
  );
};
