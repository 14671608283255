import React, { useState, useEffect } from "react";

import {
  BackJauge,
  ColorJauge,
  SingulariteHowCircle,
  SingulariteWhyCircle,
  SingulariteWithCircle,
  WrapperJauges,
  WrapperOneJauge,
  WrapperModule,
  WrapperPicture,
  WrapperBigArrow,
  BigArrow,
  WrapperAllTextResults,
  WrapperTextResult,
  BulletTextResult,
  TextResult,
  WrapperCirclePicture,
  WrapperIconDisc,
  WrapperIconIntMul,
  WrapperIconValMot,
  WrapperPage,
  StyledTextCircle,
  WrapperTextAndCircle,
  Divider,
  TitleSoftSkils,
  SubTitleSoftSkils,
  WrapperPlayer,
  PlayerRed,
  PlayerWhiteTriangle,
  PlayerText,
  WrapperHeaderText,
  WrapperAllModule,
} from "./StudentSingularitySettings.style";
import IconButton from "@mui/material/IconButton";
import TableViewIcon from "@mui/icons-material/TableView";
import {
  discColors,
  motivationsColors,
  intellMultipleColors,
} from "../../utils/theme";

import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { colors } from "../../utils/colors";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { StyledButton } from "../../utils/StyledButton.style";
import { DialogInput } from "../../utils/dialog/DialogInput";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useParams } from "react-router-dom";

import {
  GET_CURRENT_USER,
  GET_DISC_BY_ID,
  GET_USER_BY_ID,
} from "../../utils/graphql/user/query";
import { useQuery, useMutation } from "@apollo/client";

import { CREATE_GROUP } from "../../utils/graphql/group/mutation";
import { GET_SCHOOLS } from "../../utils/graphql/school/query";
import { useHover } from "../../utils/hooks/useHover";
import {
  discFirst,
  discSecond,
  discLast,
  intelligenceMulFirst,
  intelligenceMulSecond,
  intelligenceMulLast,
  motivationFirst,
  motivationLast,
  motivationSecond,
  StyledRatingB,
  StyledRatingG,
  StyledRatingY,
  StyledRatingR,
  StyledRatingLM,
  StyledRatingVL,
  StyledRatingN,
  StyledRatingK,
  StyledRatingINTER,
  StyledRatingINTRA,
  StyledRatingM,
  StyledRatingS,
  StyledRatingC,
  StyledRatingE,
  StyledRatingSO,
  StyledRatingT,
  StyledRatingI,
  StyledRatingU,
} from "../../utils/theme";
import {
  UPDATE_DISC,
  UPDATE_INTMUL,
  UPDATE_VALMOT,
} from "../../utils/graphql/module/mutation";
import LoadingButton from "@mui/lab/LoadingButton";
import { GET_GROUP_BY_ID } from "../../utils/graphql/group/query";

export const StudentSingularitySettings = ({
  propsDevice,
  currentUserTest,
  id,
  resultDisc,
  resultIntMul,
  resultValMot,
}) => {
  let navigate = useNavigate();
  // Etat pour la modale
  const [openDiscDialog, setOpenDiscDialog] = useState(false);
  const [openIntMulDialog, setOpenIntMulDialog] = useState(false);
  const [openValMotDialog, setOpenValMotDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [openAlert, setAlert] = useState(false);
  // ouverture des vidéos
  const [openVideoDisc, setVideoDisc] = useState(false);
  const [openVideoIntMul, setVideoIntMul] = useState(false);
  const [openVideoValMot, setVideoValMot] = useState(false);
  // hover des sections
  const [hoverDiscRef, isDiscHovered] = useHover();
  const [hoverIntMulRef, isIntMulHovered] = useHover();
  const [hoverValMotRef, isValMotHovered] = useHover();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const {
    loading: loadingGroup,
    error: errorGroup,
    data: dataGroup,
  } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      getGroupByIdId: parseInt(currentUser?.groupId),
    },
  });

  // On initilise les valeurs pour la notation en locale au chargement de la page si l'objet module est null
  const [discValueRating, setDiscRatingValue] = useState({
    r: resultDisc?.r / 10 || 0,
    y: resultDisc?.y / 10 || 0,
    g: resultDisc?.g / 10 || 0,
    b: resultDisc?.b / 10 || 0,
  });
  const [intMulValueRating, setIntMulRatingValue] = useState({
    lm: resultIntMul?.lm / 10 || 0,
    vl: resultIntMul?.vl / 10 || 0,
    m: resultIntMul?.m / 10 || 0,
    s: resultIntMul?.s / 10 || 0,
    k: resultIntMul?.k / 10 || 0,
    inter: resultIntMul?.inter / 10 || 0,
    intra: resultIntMul?.intra / 10 || 0,
    n: resultIntMul?.n / 10 || 0,
  });
  const [valMotValueRating, setValMotRatingValue] = useState({
    c: resultValMot?.c / 10 || 0,
    e: resultValMot?.e / 10 || 0,
    t: resultValMot?.t / 10 || 0,
    s: resultValMot?.s / 10 || 0,
    indiv: resultValMot?.indiv / 10 || 0,
    util: resultValMot?.util / 10 || 0,
  });

  /*   const { loading: loadingUser, data: dataUser } = useQuery(GET_USER_BY_ID, {
    variables: {
      getUserByIdId: parseInt(idUser),
    },
  }); */

  const { data: dataCurrentUser, loading: loadingCurrentUser } =
    useQuery(GET_CURRENT_USER);

  const [updateDisc, { dataUpdateDisc, loadingUpdateDisc, errorUpdateDisc }] =
    useMutation(UPDATE_DISC);

  const [
    updateIntMul,
    { dataUpdateIntMul, loadingUpdateIntMul, errorUpdateIntMul },
  ] = useMutation(UPDATE_INTMUL);
  const [
    updateValMot,
    { dataUpdateValMot, loadingUpdateValMot, errorUpdateValMot },
  ] = useMutation(UPDATE_VALMOT);

  const updateDiscResult = async (discValueRating) => {
    const data = await updateDisc({
      variables: {
        discUpdateInput: {
          id: parseInt(resultDisc?.id),
          r: parseInt(discValueRating?.r * 10),

          y: parseInt(discValueRating?.y * 10),

          b: parseInt(discValueRating?.b * 10),
          g: parseInt(discValueRating?.g * 10),
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateDisc: {
            __typename: "Disc",
            id: parseInt(resultDisc?.id),
            r: parseInt(discValueRating?.r * 10),

            y: parseInt(discValueRating?.y * 10),

            b: parseInt(discValueRating?.b * 10),
            g: parseInt(discValueRating?.g * 10),
          },
        },
      },
    });
    if (data) {
      setAlert(true);
    }
  };

  const updateIntMulResult = async (intMulValueRating) => {
    const data = await updateIntMul({
      variables: {
        intelligenceMultipleUpdateInput: {
          id: parseInt(resultIntMul?.id),
          lm: parseInt(intMulValueRating?.lm * 10),

          vl: parseInt(intMulValueRating?.vl * 10),

          s: parseInt(intMulValueRating?.s * 10),

          k: parseInt(intMulValueRating?.k * 10),
          n: parseInt(intMulValueRating?.n * 10),

          m: parseInt(intMulValueRating?.m * 10),

          inter: parseInt(intMulValueRating?.inter * 10),

          intra: parseInt(intMulValueRating?.intra * 10),
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateIntMul: {
            __typename: "IntelligenceMultiple",
            id: parseInt(resultIntMul?.id),
            lm: parseInt(intMulValueRating?.lm * 10),

            vl: parseInt(intMulValueRating?.vl * 10),

            s: parseInt(intMulValueRating?.s * 10),

            k: parseInt(intMulValueRating?.k * 10),
            n: parseInt(intMulValueRating?.n * 10),

            m: parseInt(intMulValueRating?.m * 10),

            inter: parseInt(intMulValueRating?.inter * 10),

            intra: parseInt(intMulValueRating?.intra * 10),
          },
        },
      },
    });
    if (data) {
      setAlert(true);
    }
  };

  const updateValMotResult = async (valMotValueRating) => {
    const data = await updateValMot({
      variables: {
        valeurMotivationUpdateInput: {
          id: parseInt(resultValMot?.id),
          c: parseInt(valMotValueRating?.c * 10),

          e: parseInt(valMotValueRating?.e * 10),

          s: parseInt(valMotValueRating?.s * 10),

          t: parseInt(valMotValueRating?.t * 10),
          indiv: parseInt(valMotValueRating?.indiv * 10),

          util: parseInt(valMotValueRating?.util * 10),
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateValMot: {
            __typename: "ValeurMotivation",
            id: parseInt(resultValMot?.id),
            c: parseInt(valMotValueRating?.c * 10),

            e: parseInt(valMotValueRating?.e * 10),

            s: parseInt(valMotValueRating?.s * 10),

            t: parseInt(valMotValueRating?.t * 10),
            indiv: parseInt(valMotValueRating?.indiv * 10),

            util: parseInt(valMotValueRating?.util * 10),
          },
        },
      },
    });
    if (data) {
      setAlert(true);
    }
  };

  const handleClose = (select) => {
    switch (select) {
      case 1:
        setOpenDiscDialog(false);

        break;
      case 2:
        setOpenIntMulDialog(false);

        break;

      case 3:
        setOpenValMotDialog(false);

        break;
      default:
        break;
    }
  };
  const handleOpen = (select) => {
    switch (select) {
      case 1:
        setOpenDiscDialog(true);

        break;
      case 2:
        setOpenIntMulDialog(true);

        break;

      case 3:
        setOpenValMotDialog(true);

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (dataCurrentUser) {
      setCurrentUser(dataCurrentUser?.getCurrentUser);
    } else {
      navigate("/login");
    }
  }, [dataCurrentUser]);

  return (
    <WrapperPage>
      {propsDevice?.device !== "tabletOrMobile" ? (
        <>
          <WrapperTextAndCircle>
            <StyledTextCircle
              hover={isDiscHovered || isIntMulHovered || isValMotHovered}
            >
              {isDiscHovered
                ? "COMMENT ?"
                : isIntMulHovered
                  ? "AVEC QUOI ? "
                  : isValMotHovered
                    ? "POURQUOI?"
                    : null}
            </StyledTextCircle>
            <SingulariteHowCircle hover={isDiscHovered}>
              <SingulariteWithCircle hover={isIntMulHovered}>
                <SingulariteWhyCircle hover={isValMotHovered} />
              </SingulariteWithCircle>
            </SingulariteHowCircle>
          </WrapperTextAndCircle>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Divider />
            {loadingCurrentUser ? (
              <LoadingButton loading />
            ) : (
              <TitleSoftSkils>
                Configurer mon profil soft skills 🌱{" "}
              </TitleSoftSkils>
            )}

            <Divider />
          </div>

          {/*       {console.log("juste pour savoir ", module && disc && valMot && intMul)} */}
          {resultDisc?.id ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {dataGroup?.getGroupById?.activatedModules.includes("DISC") ? (
                <WrapperAllModule>
                  <WrapperHeaderText>
                    <SubTitleSoftSkils size="12px">
                      Comprendre mes comportements préférentiels - DISC
                    </SubTitleSoftSkils>
                    <div>
                      <IconButton
                        aria-label="edit"
                        sx={{ color: "#5BC5F2" }}
                        onClick={() => handleOpen(1)}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </WrapperHeaderText>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <WrapperPlayer onClick={() => setVideoDisc(!openVideoDisc)}>
                      <PlayerText>VOIR LA VIDÉO</PlayerText>

                      <PlayerRed>
                        <PlayerWhiteTriangle />
                      </PlayerRed>
                    </WrapperPlayer>
                    {openVideoDisc ? (
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube-nocookie.com/embed/oMqELlp1PSs"
                        title="YouTube video player"
                        frameborder="0"
                        allow=" autoplay;   picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <></>
                    )}
                    <WrapperModule
                      ref={hoverDiscRef}
                      onClick={() => handleOpen(1)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <WrapperPicture>
                            <WrapperCirclePicture>
                              <WrapperIconDisc />
                            </WrapperCirclePicture>
                          </WrapperPicture>

                          <WrapperJauges>
                            {Object.entries(resultDisc)
                              .filter(([key]) =>
                                ["r", "y", "g", "b"].includes(key)
                              )
                              .sort(
                                ([a], [b]) =>
                                  ["r", "y", "g", "b"].indexOf(a) -
                                  ["r", "y", "g", "b"].indexOf(b)
                              ) // Tri selon l'ordre souhaité
                              .map((item) => {
                                return (
                                  <WrapperOneJauge>
                                    <BackJauge>
                                      <ColorJauge
                                        height={item[1]}
                                        color={
                                          discColors.find(
                                            (x) => x.label === item[0]
                                          )?.color
                                        }
                                      />
                                    </BackJauge>
                                    <div>
                                      {" "}
                                      {item[0] === "r"
                                        ? "d"
                                        : item[0] === "y"
                                          ? "i"
                                          : item[0] === "g"
                                            ? "s"
                                            : item[0] === "b"
                                              ? "c"
                                              : null}
                                    </div>
                                  </WrapperOneJauge>
                                );
                              })}
                          </WrapperJauges>
                        </div>
                        <WrapperBigArrow>
                          <BigArrow hover={isDiscHovered} />
                        </WrapperBigArrow>
                        <WrapperAllTextResults>
                          {resultDisc ? (
                            <>
                              <WrapperTextResult>
                                <BulletTextResult hover={isDiscHovered} />{" "}
                                <TextResult>
                                  {"Comportements préférentiels : "}
                                  {discFirst(resultDisc)[0]}
                                  {discFirst(resultDisc)[1]
                                    ? ` et ${discFirst(resultDisc)[1]}`
                                    : ""}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isDiscHovered} />{" "}
                                <TextResult>
                                  {"Comportement secondaire : "}
                                  {discSecond(resultDisc)[0]}
                                  {discSecond(resultDisc)[1]
                                    ? ` et ${discSecond(resultDisc)[1]}`
                                    : ""}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isDiscHovered} />{" "}
                                <TextResult>
                                  {"Comportement énergivore : "}
                                  {discLast(resultDisc)[0]}
                                  {discLast(resultDisc)[1]
                                    ? ` et ${discLast(resultDisc)[1]}`
                                    : ""}
                                </TextResult>
                              </WrapperTextResult>{" "}
                            </>
                          ) : (
                            <LoadingButton loading>Patientez</LoadingButton>
                          )}
                        </WrapperAllTextResults>
                      </div>
                    </WrapperModule>
                    <Button
                      style={{
                        color: "#5BC5F2",
                        fontSize: "10px",
                        backgroundColor: "#fff",
                        width: "200px",
                      }}
                      variant="outlined"
                      size="large"
                      startIcon={<TableViewIcon />}
                      onClick={() => {
                        alert("Télécharger le document ?");
                        window.open(
                          "https://www.awayke.org/wp-content/uploads/2024/09/Questionnaire-DISC.xlsx",
                          "_blank"
                        );
                      }}
                    >
                      Version Excel du Questionnaire indicatif
                    </Button>
                  </div>
                </WrapperAllModule>
              ) : (
                <></>
              )}

              {dataGroup?.getGroupById?.activatedModules.includes("INTMUL") ? (
                <WrapperAllModule>
                  <WrapperHeaderText>
                    {" "}
                    <SubTitleSoftSkils size="12px">
                      Identifier mes différentes intelligences
                    </SubTitleSoftSkils>
                    <div>
                      <IconButton
                        aria-label="edit"
                        sx={{ color: "#5BC5F2" }}
                        onClick={() => handleOpen(2)}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </WrapperHeaderText>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <WrapperPlayer
                      onClick={() => setVideoIntMul(!openVideoIntMul)}
                    >
                      <PlayerText>VOIR LA VIDÉO</PlayerText>

                      <PlayerRed>
                        <PlayerWhiteTriangle></PlayerWhiteTriangle>
                      </PlayerRed>
                    </WrapperPlayer>
                    {openVideoIntMul ? (
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/_2wN-ZGfTWE?si=4pftWCbRbKtl-O8_"
                        title="YouTube video player"
                        frameborder="0"
                        allow=" autoplay;   picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <></>
                    )}

                    <WrapperModule
                      ref={hoverIntMulRef}
                      onClick={() => handleOpen(2)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <WrapperPicture>
                            <WrapperCirclePicture>
                              <WrapperIconIntMul />
                            </WrapperCirclePicture>
                          </WrapperPicture>
                          <WrapperJauges>
                            {Object.entries(resultIntMul)
                              .filter(([key]) =>
                                [
                                  "lm",
                                  "vl",
                                  "k",
                                  "n",
                                  "s",
                                  "inter",
                                  "intra",
                                  "m",
                                ].includes(key)
                              )
                              .sort((a, b) => a[1] - b[1])
                              .map((item) => {
                                return (
                                  <WrapperOneJauge>
                                    <BackJauge>
                                      <ColorJauge
                                        height={item[1]}
                                        color={
                                          intellMultipleColors.find(
                                            (x) => x.label === item[0]
                                          )?.color
                                        }
                                      />
                                    </BackJauge>
                                    <div> {item[0]}</div>
                                  </WrapperOneJauge>
                                );
                              })}
                          </WrapperJauges>
                        </div>
                        <WrapperBigArrow>
                          <BigArrow hover={isIntMulHovered} />
                        </WrapperBigArrow>
                        <WrapperAllTextResults>
                          {resultIntMul ? (
                            <>
                              <WrapperTextResult>
                                <BulletTextResult hover={isIntMulHovered} />
                                <TextResult>
                                  {"Intelligences fortes : "}
                                  {intelligenceMulFirst(resultIntMul)[0]}
                                  {intelligenceMulFirst(resultIntMul)[1]
                                    ? " et "
                                    : ""}
                                  {intelligenceMulFirst(resultIntMul)[1]}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isIntMulHovered} />
                                <TextResult>
                                  {"Intelligences acquises : "}
                                  {intelligenceMulSecond(resultIntMul)[0]}
                                  {intelligenceMulSecond(resultIntMul)[1]
                                    ? ", "
                                    : ""}
                                  {intelligenceMulSecond(resultIntMul)[1]}
                                  {intelligenceMulSecond(resultIntMul)[2]
                                    ? " et "
                                    : ""}
                                  {intelligenceMulSecond(resultIntMul)[2]}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isIntMulHovered} />
                                <TextResult>
                                  {"Intelligences en travail : "}
                                  {intelligenceMulLast(resultIntMul)[0]}
                                  {intelligenceMulLast(resultIntMul)[1]
                                    ? ", "
                                    : ""}
                                  {intelligenceMulLast(resultIntMul)[1]}
                                  {intelligenceMulLast(resultIntMul)[2]
                                    ? " et "
                                    : ""}
                                  {intelligenceMulLast(resultIntMul)[2]}
                                </TextResult>
                              </WrapperTextResult>
                            </>
                          ) : (
                            <LoadingButton loading>Patientez</LoadingButton>
                          )}
                        </WrapperAllTextResults>
                      </div>
                    </WrapperModule>
                    <Button
                      style={{
                        color: "#5BC5F2",
                        fontSize: "10px",
                        backgroundColor: "#fff",
                        width: "200px",
                      }}
                      variant="outlined"
                      size="large"
                      startIcon={<TableViewIcon />}
                      onClick={() => {
                        alert("Télécharger le document ?");
                        window.open(
                          "https://www.awayke.org/wp-content/uploads/2024/09/Questionnaire-VALEURS-ET-MOTIVATIONS.xlsx",
                          "_blank"
                        );
                      }}
                    >
                      Version Excel du Questionnaire indicatif
                    </Button>
                  </div>
                </WrapperAllModule>
              ) : (
                <></>
              )}

              {dataGroup?.getGroupById?.activatedModules.includes("VALMOT") ? (
                <WrapperAllModule>
                  <WrapperHeaderText>
                    {" "}
                    <SubTitleSoftSkils size="12px">
                      Identifier mes leviers d'action
                    </SubTitleSoftSkils>
                    <div>
                      <IconButton
                        aria-label="edit"
                        sx={{ color: "#5BC5F2" }}
                        onClick={() => handleOpen(3)}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </WrapperHeaderText>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <WrapperPlayer
                      onClick={() => setVideoValMot(!openVideoValMot)}
                    >
                      <PlayerText>VOIR LA VIDÉO</PlayerText>

                      <PlayerRed>
                        <PlayerWhiteTriangle></PlayerWhiteTriangle>
                      </PlayerRed>
                    </WrapperPlayer>
                    {openVideoValMot ? (
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/5RzefqE5D6w?si=pS7r1xVzltTc101B"
                        title="YouTube video player"
                        frameborder="0"
                        allow=" autoplay;   picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <></>
                    )}
                    <WrapperModule
                      ref={hoverValMotRef}
                      onClick={() => handleOpen(3)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <WrapperPicture>
                            <WrapperCirclePicture>
                              <WrapperIconValMot />
                            </WrapperCirclePicture>
                          </WrapperPicture>
                          <WrapperJauges>
                            {Object.entries(resultValMot)
                              .filter(([key]) =>
                                ["c", "e", "t", "indiv", "util", "s"].includes(
                                  key
                                )
                              )
                              .sort((a, b) => a[1] - b[1])
                              .map((item) => {
                                return (
                                  <WrapperOneJauge>
                                    <BackJauge>
                                      <ColorJauge
                                        height={item[1]}
                                        color={
                                          motivationsColors.find(
                                            (x) => x.label === item[0]
                                          )?.color
                                        }
                                      />
                                    </BackJauge>
                                    <div> {item[0]}</div>
                                  </WrapperOneJauge>
                                );
                              })}
                          </WrapperJauges>{" "}
                        </div>
                        <WrapperBigArrow>
                          <BigArrow hover={isValMotHovered} />
                        </WrapperBigArrow>
                        <WrapperAllTextResults>
                          {resultValMot ? (
                            <>
                              <WrapperTextResult>
                                <BulletTextResult hover={isValMotHovered} />{" "}
                                <TextResult>
                                  {"Leviers de motivation fort : "}
                                  {motivationFirst(resultValMot)[0]}{" "}
                                  {motivationFirst(resultValMot)[1]
                                    ? "et "
                                    : ""}
                                  {motivationFirst(resultValMot)[1]}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isValMotHovered} />{" "}
                                <TextResult>
                                  {"Levier de motivation moyens : "}
                                  {motivationSecond(resultValMot)[0]}
                                  {motivationSecond(resultValMot)[1]
                                    ? ", "
                                    : ""}
                                  {motivationSecond(resultValMot)[1]}
                                  {motivationSecond(resultValMot)[2]
                                    ? " et "
                                    : ""}
                                  {motivationSecond(resultValMot)[2]}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isValMotHovered} />
                                <TextResult>
                                  {"Leviers de motivation faibles : "}
                                  {motivationLast(resultValMot)[0]}
                                  {motivationLast(resultValMot)[1] ? ", " : ""}
                                  {motivationLast(resultValMot)[1]}
                                  {motivationLast(resultValMot)[2]
                                    ? " et "
                                    : ""}
                                  {motivationLast(resultValMot)[2]}
                                </TextResult>
                              </WrapperTextResult>{" "}
                            </>
                          ) : (
                            <LoadingButton loading>Patientez</LoadingButton>
                          )}
                        </WrapperAllTextResults>
                      </div>
                    </WrapperModule>
                    <Button
                      style={{
                        color: "#5BC5F2",
                        fontSize: "10px",
                        backgroundColor: "#fff",
                        width: "200px",
                      }}
                      variant="outlined"
                      size="large"
                      startIcon={<TableViewIcon />}
                      onClick={() => {
                        alert("Télécharger le document ?");
                        window.open(
                          "https://www.awayke.org/wp-content/uploads/2024/09/Questionnaire-INTELLIGENCES-MULTIPLES.xlsx",
                          "_blank"
                        );
                      }}
                    >
                      Version Excel du Questionnaire indicatif
                    </Button>
                  </div>
                </WrapperAllModule>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <LoadingButton loading>Patientez</LoadingButton>
          )}
        </>
      ) : (
        <>
          {/*  ################################################################################     PHONE*/}

          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Divider />
            {loadingCurrentUser ? (
              <LoadingButton loading />
            ) : (
              <TitleSoftSkils>
                Configurer mon profil soft skills 🌱{" "}
              </TitleSoftSkils>
            )}

            <Divider />
          </div>

          {/*       {console.log("juste pour savoir ", module && disc && valMot && intMul)} */}
          {resultDisc?.id ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              {dataGroup?.getGroupById?.activatedModules.includes("DISC") ? (
                <WrapperAllModule>
                  <WrapperHeaderText>
                    <div>
                      <IconButton
                        aria-label="edit"
                        sx={{ color: "#5BC5F2" }}
                        onClick={() => handleOpen(1)}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                    <SubTitleSoftSkils size="12px">
                      Comprendre mes comportements préférentiels - DISC
                    </SubTitleSoftSkils>

                    <WrapperPlayer onClick={() => setVideoDisc(!openVideoDisc)}>
                      <PlayerText size="12px">VOIR LA VIDÉO</PlayerText>

                      <PlayerRed scale="0.7">
                        <PlayerWhiteTriangle></PlayerWhiteTriangle>
                      </PlayerRed>
                    </WrapperPlayer>
                  </WrapperHeaderText>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {openVideoDisc ? (
                      <iframe
                        width="400"
                        height="245"
                        src="https://www.youtube-nocookie.com/embed/oMqELlp1PSs"
                        title="YouTube video player"
                        frameborder="0"
                        allow=" autoplay;   picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <></>
                    )}
                    <WrapperModule
                      ref={hoverDiscRef}
                      onClick={() => handleOpen(1)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <WrapperJauges>
                            {Object.entries(resultDisc)
                              .filter(([key]) =>
                                ["r", "b", "g", "y"].includes(key)
                              )
                              .sort(
                                ([a], [b]) =>
                                  ["r", "y", "g", "b"].indexOf(a) -
                                  ["r", "y", "g", "b"].indexOf(b)
                              ) // Tri selon l'ordre souhaité
                              .map((item) => {
                                return (
                                  <WrapperOneJauge>
                                    <BackJauge>
                                      <ColorJauge
                                        height={item[1]}
                                        color={
                                          discColors.find(
                                            (x) => x.label === item[0]
                                          )?.color
                                        }
                                      />
                                    </BackJauge>
                                    <div>
                                      {" "}
                                      {item[0] === "y"
                                        ? "i"
                                        : item[0] === "r"
                                          ? "d"
                                          : item[0] === "b"
                                            ? "c"
                                            : item[0] === "g"
                                              ? "s"
                                              : null}
                                    </div>
                                  </WrapperOneJauge>
                                );
                              })}
                          </WrapperJauges>
                        </div>
                        <WrapperBigArrow>
                          <BigArrow
                            hover={isDiscHovered}
                            transform={"rotate(90deg)"}
                          />
                        </WrapperBigArrow>
                        <WrapperAllTextResults>
                          {resultDisc ? (
                            <>
                              <WrapperTextResult>
                                <BulletTextResult hover={isDiscHovered} />{" "}
                                <TextResult>
                                  {"Comportements préférentiels : "}
                                  {discFirst(resultDisc)[0]}
                                  {discFirst(resultDisc)[1]
                                    ? ` et ${discFirst(resultDisc)[1]}`
                                    : ""}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isDiscHovered} />{" "}
                                <TextResult>
                                  {"Comportement secondaire : "}
                                  {discSecond(resultDisc)[0]}
                                  {discSecond(resultDisc)[1]
                                    ? ` et ${discSecond(resultDisc)[1]}`
                                    : ""}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isDiscHovered} />{" "}
                                <TextResult>
                                  {"Comportement énergivore : "}
                                  {discLast(resultDisc)[0]}
                                  {discLast(resultDisc)[1]
                                    ? ` et ${discLast(resultDisc)[1]}`
                                    : ""}
                                </TextResult>
                              </WrapperTextResult>{" "}
                            </>
                          ) : (
                            <LoadingButton loading>Patientez</LoadingButton>
                          )}
                        </WrapperAllTextResults>
                      </div>
                    </WrapperModule>
                    <Button
                      style={{
                        color: "#5BC5F2",
                        fontSize: "10px",
                        backgroundColor: "#fff",
                        width: "200px",
                      }}
                      variant="outlined"
                      size="large"
                      startIcon={<TableViewIcon />}
                      onClick={() => {
                        alert("Télécharger le document ?");
                        window.open(
                          "https://docs.google.com/spreadsheets/d/19py7RCNCypVoC5kq0la8m1ma31J8umOy/edit?usp=drive_link&ouid=102392483008842704160&rtpof=true&sd=true",
                          "_blank"
                        );
                      }}
                    >
                      Version Excel du Questionnaire indicatif
                    </Button>
                  </div>
                </WrapperAllModule>
              ) : (
                <></>
              )}
              {dataGroup?.getGroupById?.activatedModules.includes("INTMUL") ? (
                <WrapperAllModule>
                  <WrapperHeaderText>
                    {" "}
                    <div>
                      <IconButton
                        aria-label="edit"
                        sx={{ color: "#5BC5F2" }}
                        onClick={() => handleOpen(2)}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                    <SubTitleSoftSkils size="12px">
                      Identifier mes différentes intelligences
                    </SubTitleSoftSkils>
                    <WrapperPlayer
                      onClick={() => setVideoIntMul(!openVideoIntMul)}
                    >
                      <PlayerText size="12px">VOIR LA VIDÉO</PlayerText>

                      <PlayerRed scale="0.7">
                        <PlayerWhiteTriangle></PlayerWhiteTriangle>
                      </PlayerRed>
                    </WrapperPlayer>
                  </WrapperHeaderText>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {openVideoIntMul ? (
                      <iframe
                        width="400"
                        height="245"
                        src="https://www.youtube.com/embed/_2wN-ZGfTWE?si=4pftWCbRbKtl-O8_"
                        title="YouTube video player"
                        frameborder="0"
                        allow=" autoplay;   picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <></>
                    )}

                    <WrapperModule
                      ref={hoverIntMulRef}
                      onClick={() => handleOpen(2)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <WrapperJauges>
                            {Object.entries(resultIntMul)
                              .filter(([key]) =>
                                [
                                  "lm",
                                  "vl",
                                  "k",
                                  "n",
                                  "s",
                                  "inter",
                                  "intra",
                                  "m",
                                ].includes(key)
                              )
                              .sort((a, b) => a[1] - b[1])
                              .map((item) => {
                                return (
                                  <WrapperOneJauge>
                                    <BackJauge>
                                      <ColorJauge
                                        height={item[1]}
                                        color={
                                          intellMultipleColors.find(
                                            (x) => x.label === item[0]
                                          )?.color
                                        }
                                      />
                                    </BackJauge>
                                    <div> {item[0]}</div>
                                  </WrapperOneJauge>
                                );
                              })}
                          </WrapperJauges>
                        </div>
                        <WrapperBigArrow>
                          <BigArrow
                            hover={isIntMulHovered}
                            transform={"rotate(90deg)"}
                          />
                        </WrapperBigArrow>
                        <WrapperAllTextResults>
                          {resultIntMul ? (
                            <>
                              <WrapperTextResult>
                                <BulletTextResult hover={isIntMulHovered} />
                                <TextResult>
                                  {"Intelligences fortes : "}
                                  {intelligenceMulFirst(resultIntMul)[0]}
                                  {intelligenceMulFirst(resultIntMul)[1]
                                    ? " et "
                                    : ""}
                                  {intelligenceMulFirst(resultIntMul)[1]}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isIntMulHovered} />
                                <TextResult>
                                  {"Intelligences acquises : "}
                                  {intelligenceMulSecond(resultIntMul)[0]}
                                  {intelligenceMulSecond(resultIntMul)[1]
                                    ? ", "
                                    : ""}
                                  {intelligenceMulSecond(resultIntMul)[1]}
                                  {intelligenceMulSecond(resultIntMul)[2]
                                    ? " et "
                                    : ""}
                                  {intelligenceMulSecond(resultIntMul)[2]}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isIntMulHovered} />
                                <TextResult>
                                  {"Intelligences en travail : "}
                                  {intelligenceMulLast(resultIntMul)[0]}
                                  {intelligenceMulLast(resultIntMul)[1]
                                    ? ", "
                                    : ""}
                                  {intelligenceMulLast(resultIntMul)[1]}
                                  {intelligenceMulLast(resultIntMul)[2]
                                    ? " et "
                                    : ""}
                                  {intelligenceMulLast(resultIntMul)[2]}
                                </TextResult>
                              </WrapperTextResult>
                            </>
                          ) : (
                            <LoadingButton loading>Patientez</LoadingButton>
                          )}
                        </WrapperAllTextResults>
                      </div>
                    </WrapperModule>
                    <Button
                      style={{
                        color: "#5BC5F2",
                        fontSize: "10px",
                        backgroundColor: "#fff",
                        width: "200px",
                      }}
                      variant="outlined"
                      size="large"
                      startIcon={<TableViewIcon />}
                      onClick={() => {
                        alert("Télécharger le document ?");
                        window.open(
                          "https://www.awayke.org/wp-content/uploads/2024/09/Questionnaire-VALEURS-ET-MOTIVATIONS.xlsx",
                          "_blank"
                        );
                      }}
                    >
                      Version Excel du Questionnaire indicatif
                    </Button>
                  </div>
                </WrapperAllModule>
              ) : (
                <></>
              )}
              {dataGroup?.getGroupById?.activatedModules.includes("VALMOT") ? (
                <WrapperAllModule>
                  <WrapperHeaderText>
                    {" "}
                    <div>
                      <IconButton
                        aria-label="edit"
                        sx={{ color: "#5BC5F2" }}
                        onClick={() => handleOpen(3)}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                    <SubTitleSoftSkils size="12px">
                      Identifier mes leviers d'action
                    </SubTitleSoftSkils>
                    <WrapperPlayer
                      onClick={() => setVideoValMot(!openVideoValMot)}
                    >
                      <PlayerText size="12px">VOIR LA VIDÉO</PlayerText>

                      <PlayerRed scale="0.7">
                        <PlayerWhiteTriangle></PlayerWhiteTriangle>
                      </PlayerRed>
                    </WrapperPlayer>
                  </WrapperHeaderText>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {openVideoValMot ? (
                      <iframe
                        width="400"
                        height="245"
                        src="https://www.youtube.com/embed/5RzefqE5D6w?si=pS7r1xVzltTc101B"
                        title="YouTube video player"
                        frameborder="0"
                        allow=" autoplay;   picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <></>
                    )}
                    <WrapperModule
                      ref={hoverValMotRef}
                      onClick={() => handleOpen(3)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <WrapperJauges>
                            {Object.entries(resultValMot)
                              .filter(([key]) =>
                                ["c", "e", "t", "indiv", "util", "s"].includes(
                                  key
                                )
                              )
                              .sort((a, b) => a[1] - b[1])
                              .map((item) => {
                                return (
                                  <WrapperOneJauge>
                                    <BackJauge>
                                      <ColorJauge
                                        height={item[1]}
                                        color={
                                          motivationsColors.find(
                                            (x) => x.label === item[0]
                                          )?.color
                                        }
                                      />
                                    </BackJauge>
                                    <div> {item[0]}</div>
                                  </WrapperOneJauge>
                                );
                              })}
                          </WrapperJauges>{" "}
                        </div>
                        <WrapperBigArrow>
                          <BigArrow
                            hover={isValMotHovered}
                            transform={"rotate(90deg)"}
                          />
                        </WrapperBigArrow>
                        <WrapperAllTextResults>
                          {resultValMot ? (
                            <>
                              <WrapperTextResult>
                                <BulletTextResult hover={isValMotHovered} />{" "}
                                <TextResult>
                                  {"Leviers de motivation fort : "}
                                  {motivationFirst(resultValMot)[0]}{" "}
                                  {motivationFirst(resultValMot)[1]
                                    ? "et "
                                    : ""}
                                  {motivationFirst(resultValMot)[1]}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isValMotHovered} />{" "}
                                <TextResult>
                                  {"Levier de motivation moyens : "}
                                  {motivationSecond(resultValMot)[0]}
                                  {motivationSecond(resultValMot)[1]
                                    ? ", "
                                    : ""}
                                  {motivationSecond(resultValMot)[1]}
                                  {motivationSecond(resultValMot)[2]
                                    ? " et "
                                    : ""}
                                  {motivationSecond(resultValMot)[2]}
                                </TextResult>
                              </WrapperTextResult>
                              <WrapperTextResult>
                                <BulletTextResult hover={isValMotHovered} />
                                <TextResult>
                                  {"Leviers de motivation faibles : "}
                                  {motivationLast(resultValMot)[0]}
                                  {motivationLast(resultValMot)[1] ? ", " : ""}
                                  {motivationLast(resultValMot)[1]}
                                  {motivationLast(resultValMot)[2]
                                    ? " et "
                                    : ""}
                                  {motivationLast(resultValMot)[2]}
                                </TextResult>
                              </WrapperTextResult>{" "}
                            </>
                          ) : (
                            <LoadingButton loading>Patientez</LoadingButton>
                          )}
                        </WrapperAllTextResults>
                      </div>
                    </WrapperModule>
                    <Button
                      style={{
                        color: "#5BC5F2",
                        fontSize: "10px",
                        backgroundColor: "#fff",
                        width: "200px",
                      }}
                      variant="outlined"
                      size="large"
                      startIcon={<TableViewIcon />}
                      onClick={() => {
                        alert("Télécharger le document ?");
                        window.open(
                          "https://www.awayke.org/wp-content/uploads/2024/09/Questionnaire-INTELLIGENCES-MULTIPLES.xlsx",
                          "_blank"
                        );
                      }}
                    >
                      Version Excel du Questionnaire indicatif
                    </Button>
                  </div>
                </WrapperAllModule>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <LoadingButton loading>Patientez</LoadingButton>
          )}
        </>
      )}
      <Dialog
        open={openDiscDialog}
        onClose={() => handleClose(1)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"MODIFIER LES INFORMATIONS DU DISC"}
        </DialogTitle>
        <DialogContent>
          <Typography component="legend">DOMINANCE</Typography>
          <StyledRatingR
            name="customized-color"
            value={discValueRating?.r}
            onChange={(e) => {
              setDiscRatingValue({
                ...discValueRating,
                r: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">INFLUENCE</Typography>
          <StyledRatingY
            name="customized-color"
            value={discValueRating?.y}
            onChange={(e) => {
              setDiscRatingValue({
                ...discValueRating,
                y: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">STABILITÉ</Typography>
          <StyledRatingG
            name="customized-color"
            value={discValueRating?.g}
            onChange={(e) => {
              setDiscRatingValue({
                ...discValueRating,
                g: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">CONFORMITÉ</Typography>
          {/* {discValueRating?.b} */}
          <StyledRatingB
            name="customized-color"
            value={discValueRating?.b}
            onChange={(e) => {
              setDiscRatingValue({
                ...discValueRating,
                b: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(1)}>Annuler</Button>
          <Button
            onClick={() => {
              /* window.location.reload(); */
              updateDiscResult(discValueRating);

              handleClose(1);
            }}
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openIntMulDialog}
        onClose={() => handleClose(2)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"MODIFIER LES INFORMATIONS DES INTELLIGENCES MULTIPLES"}
        </DialogTitle>
        <DialogContent>
          <Typography component="legend">LOGICO-MATHEMATIQUE</Typography>
          <StyledRatingLM
            name="customized-color"
            value={intMulValueRating?.lm}
            onChange={(e) => {
              setIntMulRatingValue({
                ...intMulValueRating,
                lm: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">VERBO-LINGUISTIQUE</Typography>
          <StyledRatingVL
            name="customized-color"
            value={intMulValueRating?.vl}
            onChange={(e) => {
              setIntMulRatingValue({
                ...intMulValueRating,
                vl: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">NATURALISTE</Typography>
          <StyledRatingN
            name="customized-color"
            value={intMulValueRating?.n}
            onChange={(e) => {
              setIntMulRatingValue({
                ...intMulValueRating,
                n: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">KYNÉSTHÉSIQUE</Typography>
          <StyledRatingK
            name="customized-color"
            value={intMulValueRating?.k}
            onChange={(e) => {
              setIntMulRatingValue({
                ...intMulValueRating,
                k: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">INTERPERSONNELLE</Typography>
          <StyledRatingINTER
            name="customized-color"
            value={intMulValueRating?.inter}
            onChange={(e) => {
              setIntMulRatingValue({
                ...intMulValueRating,
                inter: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">INTRAPERSONNELLE</Typography>
          <StyledRatingINTRA
            name="customized-color"
            value={intMulValueRating?.intra}
            onChange={(e) => {
              setIntMulRatingValue({
                ...intMulValueRating,
                intra: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">MUSICALE</Typography>
          <StyledRatingM
            name="customized-color"
            value={intMulValueRating?.m}
            onChange={(e) => {
              setIntMulRatingValue({
                ...intMulValueRating,
                m: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">SPACIALE</Typography>
          <StyledRatingS
            name="customized-color"
            value={intMulValueRating?.s}
            onChange={(e) => {
              setIntMulRatingValue({
                ...intMulValueRating,
                s: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(2)}>Annuler</Button>
          <Button
            onClick={() => {
              updateIntMulResult(intMulValueRating);
              handleClose(2);
            }}
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openValMotDialog}
        onClose={() => handleClose(3)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"MODIFIER LES INFORMATIONS DES MOTIVATIONS PROFONDES"}
        </DialogTitle>
        <DialogContent>
          <Typography component="legend">COGNITIVE</Typography>
          <StyledRatingC
            name="customized-color"
            value={valMotValueRating?.c}
            onChange={(e) => {
              setValMotRatingValue({
                ...valMotValueRating,
                c: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">ESTHÉTIQUE</Typography>
          <StyledRatingE
            name="customized-color"
            value={valMotValueRating?.e}
            onChange={(e) => {
              setValMotRatingValue({
                ...valMotValueRating,
                e: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">SOCIALE</Typography>
          <StyledRatingSO
            name="customized-color"
            value={valMotValueRating?.s}
            onChange={(e) => {
              setValMotRatingValue({
                ...valMotValueRating,
                s: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">TRADITIONNELLE</Typography>
          <StyledRatingT
            name="customized-color"
            value={valMotValueRating?.t}
            onChange={(e) => {
              setValMotRatingValue({
                ...valMotValueRating,
                t: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">INDIVIDUELLE</Typography>
          <StyledRatingI
            name="customized-color"
            value={valMotValueRating?.indiv}
            onChange={(e) => {
              setValMotRatingValue({
                ...valMotValueRating,
                indiv: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
          <Typography component="legend">UTILITAIRE</Typography>
          <StyledRatingU
            name="customized-color"
            value={valMotValueRating?.util}
            onChange={(e) => {
              setValMotRatingValue({
                ...valMotValueRating,
                util: e.target.value,
              });
            }}
            max={10}
            precision={0.25}
            icon={<CircleIcon fontSize="inherit" />}
            emptyIcon={<CircleOutlinedIcon fontSize="inherit" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(3)}>Annuler</Button>
          <Button
            onClick={() => {
              updateValMotResult(valMotValueRating);
              handleClose(3);
            }}
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={() => setAlert(false)}
      >
        <Alert
          onClose={() => setAlert(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Information modifiée!
        </Alert>
      </Snackbar>
    </WrapperPage>
  );
};
