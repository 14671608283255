import styled from "styled-components";
import backgroundLayout from "../../asset/backgroundLayout.svg";
import "../../App.css";
import "../../asset/fonts/grotasansaltrd-heavy.otf";

export const BackgroundDiv = styled.div`
  background-image: url("${backgroundLayout}");
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  padding: 10px;
  display: flex;
  justify-content: center;
`;

export const BackgroundAlign = styled.div`
  width: 100%;
  height: 100%;
`;

export const LoginButton = styled.div`
  color: #d5f2ff;
  font-style: italic;
  text-decoration-line: underline;
  cursor: pointer;
  &:hover {
    color: #fff;
    margin: 5px 0 0 0;
  }
  transition: all 150ms ease;
`;

export const InputsWrapper = styled.div`
  width: ${(props) => (props.sizeWidth === "tabletOrMobile" ? "80%" : "40%")};
  height: ${(props) => (props.sizeWidth === "tabletOrMobile" ? "75%" : "80%")};
  align-self: center;
  background: #144153 0% 0% no-repeat padding-box;
  box-shadow: 7px 7px 10px #00000029;
  border-radius: 29px;
  opacity: 0.59;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  overflow-y: scroll;
  justify-content: space-around;
`;

export const InputsWrapperRenewPassword = styled.div`
  width: ${(props) => (props.sizeWidth === "tabletOrMobile" ? "80%" : "40%")};
  height: ${(props) => (props.sizeWidth === "tabletOrMobile" ? "35%" : "40%")};
  align-self: center;
  background: #144153 0% 0% no-repeat padding-box;
  box-shadow: 7px 7px 10px #00000029;
  border-radius: 29px;
  opacity: 0.59;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  overflow-y: scroll;
  justify-content: space-around;
`;

export const TitleWaykeup = styled.div`
  // font-weight: bold;
  font-style: italic;
  // font-family: GrotaSansAltRd;
  font-size: ${(props) =>
    props.sizeWidth === "tabletOrMobile" ? "15px" : "25px"};
  margin: 10px 0 10px 0;
`;

export const Title = styled.div`
  font-weight: bold;
  //  font-family: GrotaSansAltRd;
  font-size: ${(props) =>
    props.sizeWidth === "tabletOrMobile" ? "1.5rem" : "2.5rem"};
  margin: ${(props) =>
    props.sizeWidth === "tabletOrMobile" ? "40px 0 0 0" : "15px 0 30px 0"};
`;
