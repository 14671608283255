import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Password } from "@mui/icons-material";
import { UPDATE_USER } from "./graphql/user/mutation";
import { useMutation, useQuery } from "@apollo/client";

import { GET_USER_BY_ID, GET_USER_ID_BY_TOKEN } from "./graphql/user/query";
import {
  BackgroundAlign,
  BackgroundDiv,
  InputsWrapperRenewPassword,
  LoginButton,
} from "../pages/signup/SignUp.style";
import { FormControl, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { StyledInput } from "./StyledInput.style";
import { StyledButton } from "./StyledButton.style";
import { theme } from "./theme";

export const ForgotPassword = ({ propsDevice }) => {
  let { resetToken } = useParams();
  let navigate = useNavigate();

  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    showConfirmPassword: false,
    showPassword: false,
  });

  const {
    loading: loadingTokenData,
    error: errorTokenData,
    data: dataTokenData,
  } = useQuery(GET_USER_ID_BY_TOKEN, {
    variables: {
      token: resetToken,
    },
  });
  const userId = dataTokenData?.getUserIdFromToken?.userId;

  const { loading: loadingUser, data: dataUser } = useQuery(GET_USER_BY_ID, {
    variables: {
      getUserByIdId: parseInt(userId),
    },
  });

  const [
    updateUser,
    { dataUpdatedUser, loadingUpdatedUser, errorUpdatedUser },
  ] = useMutation(UPDATE_USER);

  const updateUserFunction = async (newPassword) => {
    const data = await updateUser({
      variables: {
        userUpdateInput: {
          id: parseInt(userId),
          password: newPassword,
        },
      },
    });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <BackgroundAlign>
      <BackgroundDiv>
        <InputsWrapperRenewPassword sizeWidth={propsDevice.device}>
          <div style={{ fontSize: "25px" }}>
            👋 {dataUser?.getUserById?.name}, renouvellez votre mot de de passe
            :
          </div>
          <FormControl
            sx={{ margin: 1, width: "90%", alignItems: "stretch" }}
            variant="filled"
          >
            <div
              style={{
                margin: "5px 0px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  margin: "5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {"NOUVEAU MOT DE PASSE"}
                </div>
                <div style={{ display: "flex" }}>
                  <InputAdornment
                    position="end"
                    sx={{ color: "#fff", alignSelf: "center", margin: "5px" }}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size={"small"}
                      sx={{ color: "#fff", alignSelf: "center" }}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                  <StyledInput
                    id="standard-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    placeholder="Mot de passe"
                  />
                </div>
              </div>
              <div
                style={{
                  margin: "5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {"CONFIRMER LE NOUVEAU MOT DE PASSE"}
                </div>
                <div style={{ display: "flex" }}>
                  <InputAdornment
                    position="end"
                    sx={{ color: "#fff", alignSelf: "center", margin: "5px" }}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      size={"small"}
                      sx={{ color: "#fff", alignSelf: "center" }}
                    >
                      {values.showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                  <StyledInput
                    id="standard-adornment-confirmPassword"
                    type={values.showConfirmPassword ? "text" : "password"}
                    value={values.confirmPassword}
                    onChange={handleChange("confirmPassword")}
                    placeholder="Confirmer mot de passe"
                  />
                </div>
              </div>
            </div>
          </FormControl>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "20px 0 0 0",
            }}
          >
            <StyledButton
              colorText={theme.palette.primary.orangeText}
              onClick={async (e) => {
                e.preventDefault();
                if (values.password === values.confirmPassword) {
                  await updateUserFunction(values.password);
                  navigate("/login");
                }
                alert("Les deux entrées ne correspondent pas 😬");
              }}
            >
              {"C'est parti !"}
            </StyledButton>

            <LoginButton
              onClick={() => {
                navigate("/login");
              }}
            >
              Fausse manip ! J'ai déjà un compte, me connecter !{" "}
            </LoginButton>
          </div>
        </InputsWrapperRenewPassword>
      </BackgroundDiv>
    </BackgroundAlign>
  );
};
