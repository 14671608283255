import styled from "styled-components";

export const LayoutMenuDesktop = styled.div`
  max-width: 240px;
  height: 101vh;

  background-color: #12516d;
  box-shadow: 11px 0px 12px #00000029;
  opacity: 0.87;
`;

export const LayoutMenuDesktopHeader = styled.div`
  height: 110px;
  word-break: break-all
  background-color: transparent;
  box-shadow: 0px 10px 12px #00000029;
  border-bottom: solid 2px #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Helvetica Neue;
  font-weight: 900;
  text-transform: uppercase;
  font-size: ${(props) => (props.smaller ? "20px" : "25px")};
`;

export const MenuElement = styled.button`
  background-color: transparent;
  color: #fff;
  width: 100%;
  padding: 0.5em 0.5em 0.5em 0.5em;
  font: normal normal 600 19px Helvetica Neue;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  position: relative;
  text-align: left;
  &:hover {
    background-color: #fff;
    color: #12516d;
    opacity: 0.7;
    transition: all 0.5s ease-out;
  }
`;

export const LayoutMenuPhone = styled.div`
  width: 100%;
  height: 8vh;
  background-color: #12516d;
  box-shadow: 0px 11px 6px #00000029;
  opacity: 0.87;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleMenuPhone = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 10px;
  font-size: 18px;
  font-weight: 700;
  width: 200px;
  word-break: break-all;
`;

export const MiddleMenu = styled.div`
  display: flex;
  flex-direction: column;
  height: 88vh;
  justify-content: space-between;
`;
