import {
  BackgroundAlign,
  BackgroundDiv,
  ButtonWrapper,
  ImgLandingPhone,
  ImgLandingComputer,
  InputsWrapper,
  TitleWaykeup,
} from "./LandingPage.style";
import { StyledButton } from "../../utils/StyledButton.style";

export const LandingPage = ({ propsDevice }) => {
  const handleClickCalendar = () => {
    window.location.href =
      "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0mT6LC_ozn4qJvB5VFqYjWFJZCZ7LaRieOWhPp3eplJoKK6diY78FvhgdaDrgbVLWpAkglW7v3";
  };

  const handleClickDemo = () => {
    window.location.href =
      "https://www.waykeup.app/shared/U2FsdGVkX120%25%3D%3D7Eea83iUD8V9NaInxHyHeAQJchYxMmXY%3D";
  };

  const handleClickLogin = () => {
    window.location.href = "https://www.waykeup.app/login";
  };

  return (
    <BackgroundAlign>
      <BackgroundDiv>
        <InputsWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px 10px",
                  fontSize: "20px",
                }}
              >
                🧭
              </div>
              <TitleWaykeup sizeWidth={propsDevice.device}>
                {" "}
                WaykeUp by
              </TitleWaykeup>
            </div>
            <img
              margin="0 5px 0 5px"
              width="110px"
              src="https://www.awayke.org/wp-content/uploads/2024/08/TESTSSFOND.png"
            />
            {propsDevice.device === "tabletOrMobile" ? (
              <div style={{ overflow: "scroll", height: "70vh" }}>
                <ImgLandingPhone
                  sizeWidth={propsDevice.device}
                  src="https://www.awayke.org/wp-content/uploads/2025/01/MOckUP-Waykeup_2Phone.png"
                />
              </div>
            ) : (
              <ImgLandingComputer
                sizeWidth={propsDevice.device}
                src="https://www.awayke.org/wp-content/uploads/2025/01/MOckUP-Waykeup_1Ordi.png"
              />
            )}
          </div>
          <ButtonWrapper>
            <StyledButton
              fontSize={"12px"}
              colorText={"#002B3F"}
              onClick={handleClickLogin}
            >
              {"Me connecter 🏠 "}
            </StyledButton>
            <StyledButton
              fontSize={"12px"}
              colorText={"#002B3F"}
              onClick={handleClickCalendar}
            >
              {"Réserver une démo ! 📆"}
            </StyledButton>
            <StyledButton
              fontSize={"12px"}
              colorText={"#fff"}
              colorHover={"#002B3F"}
              backgroundColor={"#e75015"}
              onClick={handleClickDemo}
            >
              {"Voir un profil ! 🚀 "}
            </StyledButton>
          </ButtonWrapper>
        </InputsWrapper>
      </BackgroundDiv>
    </BackgroundAlign>
  );
};
