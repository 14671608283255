import "./App.css";
import { useEffect, useState } from "react";
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache,useQuery,qql } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { Router } from "./routes/Router";
import { useToken } from "./hooks/useToken";
import { useAuth } from "./hooks/AuthContext";
import { AuthProvider } from "./hooks/AuthContext";
import { useMediaQuery } from "react-responsive";





const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                getDiscById(_, {args, toReference}) {
                    return toReference({
                        __typename: "Disc",
                        id: args.id
                    });
                },
                getIntMulById(_, {args, toReference}) {
                    return toReference({
                        __typename: "IntelligenceMultiple",
                        id: args.id
                    });
                },
                getValMotById(_, {args, toReference}) {
                    return toReference({
                        __typename: "ValeurMotivation",
                        id: args.id
                    });
                },
                getGroupBySchoolId(_, {args, toReference}) {
                  
                    return toReference({
                        __typename: "Group",
                        school_id: parseInt(args.school_id)
                    });
                },
                
                
                },
                Schools: {
                    keyFields: ["id"],
            },
            Groups: {
                keyFields: ["school_id"],
        }
        }
    }
})




export default function App() {

    const [loaded, setLoaded] = useState(false);
    const [device, setDevice] = useState("desktopOrLaptop");
    //const { token, setToken, removeToken } = useToken(); 
    const {setToken, removeToken} = useToken();
    

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const isBigScreen = useMediaQuery({query: "(min-width: 1824px)"});
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 1224px)"});
    const isPortrait = useMediaQuery({query: "(orientation: portrait)"});
    const isRetina = useMediaQuery({query: "(min-resolution: 2dppx)"});

      const enviroment = process.env.APP_ENV === "development" ?  "https://waykeup-dev.herokuapp.com/" : "https://www.waykeup.app/" 
    const findDevice = () => {
        if (isDesktopOrLaptop) {
            return setDevice("desktopOrLaptop");
        }
        if (isBigScreen) {
            return setDevice("bigScreen");
        }
        if (isTabletOrMobile) {
            return setDevice("tabletOrMobile");
        }
    };

    useEffect(() => {
        findDevice();
    }, [isDesktopOrLaptop, isBigScreen, isTabletOrMobile]);

    const httpLink = createHttpLink({
        uri: `https://waykeup-api.herokuapp.com/`,
        credentials: "same-origin",
    fetch,
    
    });



     const authLink = setContext((_, {headers}) => {
        const token = localStorage.getItem("token");
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
                permissionsPolicy: `geolocation=(self "${enviroment}")`,
            },
        };
    }); 


    const errorLink = onError(({operation, graphQLErrors, networkError}) => {
        if (graphQLErrors)
            graphQLErrors.forEach((error) => {
            
             { process.env.APP_ENV === "development" ?    console.log(
                    `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
                ) : <></>}
                
            });

        if (networkError) {
             /* removeToken(); */
            //console.log(localStorage.getItem("token"));
            { process.env.APP_ENV === "development" ?   console.log(`[Network error]: ${networkError}`): <></>}
        }
    });


    const client = new ApolloClient({
        link: from([errorLink, authLink.concat(httpLink)]),
        connectToDevTools: true,
        cache: cache
    });
    


    return (
        <ApolloProvider connectToDevTools={true} client={client}>
            <AuthProvider>
            <Router device={device} retina={isRetina} portrait={isPortrait}/>
            
            </AuthProvider>
        </ApolloProvider>
    );
}

