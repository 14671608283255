import React, { useState, useEffect } from "react";
import { RoundClass } from "../../utils/roundClass/RoundClass";
import { WrapperWorkspace } from "./Workspace.style";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { colors } from "../../utils/colors";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { StyledButton } from "../../utils/StyledButton.style";
import { DialogInput } from "../../utils/dialog/DialogInput";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { tokenise } from "../../utils/tokenise";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useParams } from "react-router-dom";

import { GET_CURRENT_USER } from "../../utils/graphql/user/query";
import { useQuery, useMutation, gql } from "@apollo/client";
import { CREATE_SCHOOL } from "./graphgl/mutation";
import {
  GET_GROUPS,
  GET_GROUP_BY_SCHOOL_ID,
} from "../../utils/graphql/group/query";
import { CREATE_GROUP } from "../../utils/graphql/group/mutation";
import { GET_SCHOOLS } from "../../utils/graphql/school/query";
import { GET_AWAYKER_GROUPS_BY_AWAYKER_ID } from "../../utils/graphql/awaykerGroup/query";

export const Workspace = ({ propsDevice, currentUserTest }) => {
  let navigate = useNavigate();
  const [year, setYear] = useState(2425);
  const [openDialog, setOpenDialog] = useState(false);
  const [valueLibele, setValueLibele] = useState("");
  const [valueYear, setValueYear] = useState(2425);
  const [valueSchool, setValueSchool] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [groupList, setGroupList] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  /* const [values, setValues] = useState({
    name: "",
    surname: "",
    email: "",
    school: "",
    group: "",
    role: "",
  });
 */

  const {
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    data: dataCurrentUser,
  } = useQuery(GET_CURRENT_USER);

  const {
    loading: loadingAwaykerGroups,
    error: errorAwaykerGroups,
    data: dataAwaykerGroups,
  } = useQuery(GET_AWAYKER_GROUPS_BY_AWAYKER_ID, {
    variables: {
      userId: parseInt(dataCurrentUser?.getCurrentUser?.id),
    },
  });

  const {
    loading: loadingSchools,
    error: errorSchools,
    data: dataSchools,
  } = useQuery(GET_SCHOOLS);

  const {
    loading: loadingGroupBy,
    error: errorGroupBy,
    data: dataGroupBy,
  } = useQuery(GET_GROUP_BY_SCHOOL_ID, {
    variables: {
      schoolId: dataCurrentUser?.getCurrentUser?.school_id,
    },
  });

  useEffect(() => {
    if (dataCurrentUser?.getCurrentUser?.roleId === 3) {
      navigate("/studentSingularitySetting/");
    }
  }, [dataCurrentUser]);

  const [
    createGroup,
    { dataCreationGroup, loadingCreationGroup, errorCreationGroup },
  ] = useMutation(CREATE_GROUP, {
    update(cache, { data: { createGroup } }) {
      cache.modify({
        fields: {
          getGroups(existingsGroups = []) {
            const newGroupRef = cache.writeFragment({
              data: createGroup,
              fragment: gql`
                fragment NewGroup on Group {
                  name
                  school_id
                  year
                }
              `,
            });
            return [...existingsGroups, newGroupRef];
          },
        },
      });
    },
  });

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const handleClickOpen = () => {
    setOpenDialog(!openDialog);
  };

  const addNewItem = async (libelle, year, school) => {
    const data = await createGroup({
      variables: {
        groupInput: {
          name: libelle,
          school_id: parseInt(school),
          year: parseInt(year),
        },
        optimisticResponse: {
          __typename: "Mutation",
          createGroup: {
            __typename: "Group",
            name: libelle,
            school_id: parseInt(school),
            year: parseInt(year),
          },
        },
      },
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleConfirm = (value, year, school) => {
    setOpenDialog(false);

    addNewItem(value, year, school);
  };

  const redirect = () => {
    if (dataCurrentUser) {
      if (dataCurrentUser?.getCurrentUser?.roleId === 3) {
        navigate(`/studentSingularitySetting/`);
      }
      if (dataCurrentUser?.getCurrentUser?.roleId === 1) {
        navigate("/workspaceAdmin");
      }
    }
  };
  redirect();

  useEffect(() => {
    if (dataCurrentUser?.getCurrentUser?.roleId === 4) {
      const awaykerGroups = Object.values(
        dataAwaykerGroups?.getAwaykerGroupsByUserId || {}
      );
      const filtered = awaykerGroups.filter(
        (item) =>
          item?.group?.name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
          item?.group?.year === year
      );
      setFilteredItems(filtered);
    } else {
      const schoolGroups = Object.values(dataGroupBy?.getGroupBySchoolId || {});
      const filtered = schoolGroups.filter(
        (item) =>
          item?.name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
          item?.year === year
      );
      setFilteredItems(filtered);
    }
  }, [searchTerm, dataAwaykerGroups, dataGroupBy, dataCurrentUser, year]);

  const safeUrlFunction = (dynamicURL) => {
    const safeUrl = encodeURIComponent(dynamicURL);

    return safeUrl;
  };

  /* const cryptURL = `/list/${safeUrlFunction(
    tokenise(
      `${dataCurrentUser?.getCurrentUser?.id}/${item?.group?.id || item?.id}/${
        item?.group?.name || item?.name
      }`
    )
  )}`; */

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "20px",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      {" "}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
        }}
      >
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              font: "normal normal bold 27px Helvetica Neue",
              color: "#316880",
            }}
          >
            {"MES CLASSES"}
          </div>
          {dataCurrentUser?.getCurrentUser?.roleId == 4 ? (
            <></>
          ) : (
            <StyledButton
              grota={true}
              colorText={"#326880"}
              backgroundColor={"transparent"}
              colorHover={"#FFF"}
              fontSize={"13px"}
              isBorder={true}
              borderColor={"#326880"}
              onClick={handleClickOpen}
            >
              {"AJOUTER UNE CLASSE"}
            </StyledButton>
          )}
        </div>

        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Année</InputLabel>

          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={year}
            onChange={handleChange}
            label="Année"
            sx={{ margin: "0 10px 0 0" }}
          >
            <MenuItem value={2223}>2022-2023</MenuItem>
            <MenuItem value={2324}>2023-2024</MenuItem>
            <MenuItem value={2425}>2024-2025</MenuItem>
          </Select>
        </FormControl>
      </div>
      <input
        type="text"
        placeholder="Rechercher..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <WrapperWorkspace>
        {filteredItems.length > 0 ? (
          filteredItems.map((item, index) => (
            <RoundClass
              key={item?.id || index}
              propsDevice={propsDevice.device}
              item={item?.group || item}
              itemSize={item?.group?.size || item?.students?.length || 0}
              color={colors[index]}
              currentUserId={dataCurrentUser?.getCurrentUser?.id}
              currentUserRoleId={dataCurrentUser?.getCurrentUser?.roleId}
              navigateLink={`/list/${safeUrlFunction(
                tokenise(
                  `${dataCurrentUser?.getCurrentUser?.id}/${
                    item?.group?.id || item?.id
                  }/${item?.group?.name || item?.name}`
                )
              )}`}
            />
          ))
        ) : (
          <>Aucun élément trouvé</>
        )}
      </WrapperWorkspace>
      <div>
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogTitle>{"AJOUTER UNE CLASSE"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{""}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={"Libélé de la classe"}
              type="text"
              fullWidth
              variant="standard"
              value={valueLibele}
              onChange={(e) => setValueLibele(e.target.value)}
            />

            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="demo-simple-select-label">
                Établissement
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={valueSchool}
                onChange={(e) => setValueSchool(e.target.value)}
                label="Établissement"
                sx={{ with: "200px", margin: "0 10px 0 0" }}
              >
                {dataSchools?.getSchools?.map((item) => {
                  if (item?.id == dataCurrentUser?.getCurrentUser?.school_id) {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  }
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small">Année</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={valueYear}
                label="Année"
                onChange={(e) => setValueYear(e.target.value)}
              >
                <MenuItem value={2223}>2022-2023</MenuItem>
                <MenuItem value={2324}>2023-2024</MenuItem>
                <MenuItem value={2425}>2024-2025</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
            <Button
              onClick={() => handleConfirm(valueLibele, valueYear, valueSchool)}
            >
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
