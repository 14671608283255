import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      name
      Module {
        resultIntMul {
          id
          name
          vl
          lm
          inter
          intra
          m
          k
          n
          s
          comment
        }
        resultDisc {
          id
          name
          r
          y
          b
          g
          comment
        }
        resultValMot {
          id
          name
          e
          c
          util
          indiv
          s
          t
          comment
        }
      }
      email
      lastname
      id
      created_at
      profilVisible
      school_id
      roleId
      groupId
      role {
        label
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($getUserByIdId: ID!) {
    getUserById(id: $getUserByIdId) {
      name
      Module {
        resultIntMul {
          id
          name
          vl
          lm
          inter
          intra
          m
          k
          n
          s
          comment
        }
        resultDisc {
          id
          name
          r
          y
          b
          g
          comment
        }
        resultValMot {
          id
          name
          e
          c
          util
          indiv
          s
          t
          comment
        }
      }
      email
      lastname
      id
      created_at
      profilVisible
      school_id
      roleId
      groupId
      role {
        label
      }
    }
  }
`;

export const GET_USER_BY_GROUP_ID = gql`
  query GetUserByGroupId($groupId: Int!) {
    getUserByGroupId(groupId: $groupId) {
      name
      Module {
        resultIntMul {
          id
          name
          vl
          lm
          inter
          intra
          m
          k
          n
          s
          comment
        }
        resultDisc {
          id
          name
          r
          y
          b
          g
          comment
        }
        resultValMot {
          id
          name
          e
          c
          util
          indiv
          s
          t
          comment
        }
      }
      email
      lastname
      id
      created_at
      profilVisible
    }
  }
`;

export const GET_DISC_BY_ID = gql`
  query GetDiscById($id: ID!) {
    getDiscById(id: $id) {
      name
      Module {
        resultDisc {
          id
          name
          r
          y
          b
          g
          comment
        }
      }
    }
  }
`;

export const GET_USER_ID_BY_TOKEN = gql`
  query GetUserIdFromToken($token: String!) {
    getUserIdFromToken(token: $token) {
      userId
    }
  }
`;



export const GET_PASSWORD_TOKEN_BY_EMAIL = gql`
query Query($email: String!) {
  getResetPasswordToken(email: $email)
}
`;