import * as React from "react";

import { useNavigate } from "react-router-dom";

import { RoundNameUser, WrapperUser } from "./ButtonProfil.style";

export const ButtonProfil = ({ propsDevice, userName, userRole, userId }) => {
  let navigate = useNavigate();
  return (
    <WrapperUser onClick={() => navigate(`/usersettings/`)}>
      <RoundNameUser>
        {" "}
        {userName[0]?.toUpperCase() + userName[1]?.toUpperCase()}
      </RoundNameUser>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
        }}
      >
        <div style={{ fontSize: "12px" }}>Mon compte - paramètres </div>
        {propsDevice === "desktopOrLaptop" ? (
          userRole === "STUDENT" ? (
            "Profil Élève"
          ) : userRole === "TEACHER" ? (
            "Profil Enseignant"
          ) : (
            "Profil " + userRole
          )
        ) : (
          <></>
        )}
      </div>
    </WrapperUser>
  );
};
