

  export const phrasesMotivationDISC = [
    {text: "Relever des challenges et des défis", scores: {"dominant": 0.9, "stabilité": 0.3, "conformité": 0.4, "influent": 0.6}},
  {text: "Atteindre des objectifs ambitieux", scores: {"dominant": 0.9, "stabilité": 0.3, "conformité": 0.7, "influent": 0.2}},
  {text: "Être indépendant et autonome", scores: {"dominant": 0.9, "stabilité": 0.3, "conformité": 0.2, "influent": 0.6}},
  {text: "Pouvoir décider et performer", scores: {"dominant": 0.9, "stabilité": 0.2, "conformité": 0.2, "influent": 0.2}},
  {text: "Proposer des idées", scores: {"dominant": 0.6, "stabilité": 0.2, "conformité": 0.2, "influent": 0.9}},
  {text: "Être reconnu par les autres", scores: {"dominant": 0.6, "stabilité": 0.3, "conformité": 0.4, "influent": 0.9}},
  {text: "Créer du lien avec le groupe", scores: {"dominant": 0.2, "stabilité": 0.6, "conformité": 0.2, "influent": 0.9}},
  {text: "Être libre et pouvoir sortir du cadre", scores: {"dominant": 0.6, "stabilité": 0.3, "conformité": 0.1, "influent": 0.9}},
  {text: "Aider les autres", scores: {"dominant": 0.2, "stabilité": 0.9, "conformité": 0.3, "influent": 0.6}},
  {text: "Travailler dans un environnement harmonieux et stable", scores: {"dominant": 0.2, "stabilité": 0.9, "conformité": 0.6, "influent": 0.5}},
  {text: "Créer des liens solides avec quelqu'un", scores: {"dominant": 0.2, "stabilité": 0.9, "conformité": 0.3, "influent": 0.5}},
  {text: "Travailler de manière systématique", scores: {"dominant": 0.2, "stabilité": 0.9, "conformité": 0.6, "influent": 0.3}},
  {text: "Travailler dans un cadre clair", scores: {"dominant": 0.5, "stabilité": 0.6, "conformité": 0.9, "influent": 0.3}},
  {text: "Comprendre les choses", scores: {"dominant": 0.4, "stabilité": 0.6, "conformité": 0.9, "influent": 0.3}},
  {text: "Rechercher la perfection", scores: {"dominant": 0.5, "stabilité": 0.3, "conformité": 0.9, "influent": 0.4}},
  {text: "Avoir des indicateurs précis et mesurables", scores: {"dominant": 0.3, "stabilité": 0.5, "conformité": 0.9, "influent": 0.4}}
  ];

  export const phrasesCommunicationDISC = [
    {text: "Soutenir les buts et objectifs", scores: {"dominant": 0.9, "stabilité": 0.6, "conformité": 0.4, "influent": 0.6}},
  {text: "Parler de défis et de résultats concrets", scores: {"dominant": 0.9, "stabilité": 0.2, "conformité": 0.4, "influent": 0.2}},
  {text: "Argumenter à partir de faits et non de sentiments personnels", scores: {"dominant": 0.9, "stabilité": 0.1, "conformité": 0.5, "influent": 0.3}},
  {text: "Être précis et efficaces", scores: {"dominant": 0.9, "stabilité": 0.4, "conformité": 0.6, "influent": 0.3}},
  {text: "Être direct et affirmatif", scores: {"dominant": 0.9, "stabilité": 0.2, "conformité": 0.5, "influent": 0.6}},
  {text: "Ne pas avoir peur de la confrontation", scores: {"dominant": 0.9, "stabilité": 0.1, "conformité": 0.2, "influent": 0.3}},
  {text: "Soutenir les opinions, les idées et les rêves", scores: {"dominant": 0.6, "stabilité": 0.6, "conformité": 0.2, "influent": 0.9}},
  {text: "Être plaisant et stimulant", scores: {"dominant": 0.5, "stabilité": 0.4, "conformité": 0.1, "influent": 0.9}},
  {text: "Accepter l'originalité", scores: {"dominant": 0.5, "stabilité": 0.3, "conformité": 0.2, "influent": 0.9}},
  {text: "Être reconnaissant et faire des compliments", scores: {"dominant": 0.2, "stabilité": 0.4, "conformité": 0.6, "influent": 0.9}},
  {text: "Écouter et mettre en valeur", scores: {"dominant": 0.5, "stabilité": 0.5, "conformité": 0.5, "influent": 0.9}},
  {text: "Valoriser la créativité et le collectif", scores: {"dominant": 0.1, "stabilité": 0.6, "conformité": 0.2, "influent": 0.9}},
  {text: "Soutenir leurs sentiments en manifestant un intérêt personnalisé", scores: {"dominant": 0.1, "stabilité": 0.9, "conformité": 0.3, "influent": 0.7}},
  {text: "Créer un environnement amical", scores: {"dominant": 0.2, "stabilité": 0.9, "conformité": 0.2, "influent": 0.7}},
  {text: "Exprimer des sentiments personnels", scores: {"dominant": 0.2, "stabilité": 0.9, "conformité": 0.1, "influent": 0.5}},
  {text: "Accorder du temps pour créer de la confiance", scores: {"dominant": 0.1, "stabilité": 0.9, "conformité": 0.5, "influent": 0.4}},
  {text: "Utiliser des mots et des opinions mesurées", scores: {"dominant": 0.2, "stabilité": 0.9, "conformité": 0.3, "influent": 0.4}},
  {text: "Être simplement à l'écoute sans forcer l'expression", scores: {"dominant": 0.1, "stabilité": 0.9, "conformité": 0.6, "influent": 0.3}},
  {text: "Être précis et factuel", scores: {"dominant": 0.5, "stabilité": 0.3, "conformité": 0.9, "influent": 0.3}},
  {text: "Laisser du temps pour réfléchir", scores: {"dominant": 0.6, "stabilité": 0.3, "conformité": 0.9, "influent": 0.2}},
  {text: "Être systématique, exact, organisé et préparé", scores: {"dominant": 0.2, "stabilité": 0.6, "conformité": 0.9, "influent": 0.2}},
  {text: "Soutenir l'approche logique, rationnelle, factuelle", scores: {"dominant": 0.6, "stabilité": 0.2, "conformité": 0.9, "influent": 0.2}},
  {text: "Proposer un cadre clair et complet", scores: {"dominant": 0.5, "stabilité": 0.7, "conformité": 0.9, "influent": 0.5}},
  {text: "Échanger par écrit", scores: {"dominant": 0.2, "stabilité": 0.3, "conformité": 0.9, "influent": 0.2}}
  ];


  export const phrasesTalentDISC  = [
    {text: "Improviser naturellement dans chaque situation", scores: {dominant: 0.8, stabilité: 0.3, conformité: 0.2, influent: 0.9}},
    {text: "Persuader les autres facilement", scores: {dominant: 0.7, stabilité: 0.4, conformité: 0.2, influent: 0.9}},
    {text: "Proposer des solutions créatives", scores: {dominant: 0.6, stabilité: 0.2, conformité: 0.1, influent: 0.9}},
    {text: "Prise de parole en public simplement", scores: {dominant: 0.6, stabilité: 0.2, conformité: 0.1, influent: 0.9}},
    {text: "Inspirer et embarquer les autres aisément", scores: {dominant: 0.4, stabilité: 0.5, conformité: 0.2, influent: 0.9}},
    {text: "Faciliter les relations avec enthousiasme", scores: {dominant: 0.3, stabilité: 0.7, conformité: 0.2, influent: 0.9}},
    {text: "Regarder le positif des situations", scores: {dominant: 0.3, stabilité: 0.7, conformité: 0.2, influent: 0.9}},
    {text: "Maintenir une bonne ambiance quotidiennement", scores: {dominant: 0.2, stabilité: 0.7, conformité: 0.4, influent: 0.9}},
    {text: "Mener un groupe avec dynamisme", scores: {dominant: 0.9, stabilité: 0.4, conformité: 0.3, influent: 0.8}},
    {text: "Relever des défis avec envie", scores: {dominant: 0.9, stabilité: 0.3, conformité: 0.2, influent: 0.7}},
    {text: "Exprimer ses opinions ouvertement", scores: {dominant: 0.9, stabilité: 0.1, conformité: 0.2, influent: 0.7}},
    {text: "Travailler en équipe avec tout le monde", scores: {dominant: 0.2, stabilité: 0.9, conformité: 0.5, influent: 0.7}},
    {text: "Prendre des décisions simplement", scores: {dominant: 0.9, stabilité: 0.1, conformité: 0.2, influent: 0.6}},
    {text: "Résoudre des problèmes rapidement", scores: {dominant: 0.9, stabilité: 0.2, conformité: 0.3, influent: 0.5}},
    {text: "Prendre des risques facilement", scores: {dominant: 0.9, stabilité: 0.1, conformité: 0.2, influent: 0.5}},
    {text: "Préserver et chercher à améliorer les situations", scores: {dominant: 0.5, stabilité: 0.9, conformité: 0.3, influent: 0.5}},
    {text: "Faciliter les relations avec douceur", scores: {dominant: 0.2, stabilité: 0.9, conformité: 0.2, influent: 0.5}},
    {text: "Ecouter les besoins des autres facilement", scores: {dominant: 0.1, stabilité: 0.9, conformité: 0.4, influent: 0.4}},
    {text: "Analyser une situation complexe", scores: {dominant: 0.4, stabilité: 0.5, conformité: 0.9, influent: 0.3}},
    {text: "Gérer ses émotions simplement", scores: {dominant: 0.3, stabilité: 0.9, conformité: 0.5, influent: 0.3}},
    {text: "Construire des projets patiemment", scores: {dominant: 0.3, stabilité: 0.9, conformité: 0.6, influent: 0.3}},
    {text: "Maintenir des normes élevées", scores: {dominant: 0.7, stabilité: 0.4, conformité: 0.9, influent: 0.2}},
    {text: "Être fiable et loyal sur le long terme", scores: {dominant: 0.6, stabilité: 0.9, conformité: 0.6, influent: 0.2}},
    {text: "Développer des plans et des stratégies efficientes", scores: {dominant: 0.6, stabilité: 0.4, conformité: 0.9, influent: 0.5}},
    {text: "Appliquer des méthodes systématiquement", scores: {dominant: 0.5, stabilité: 0.9, conformité: 0.7, influent: 0.2}},
    {text: "Chercher la perfection dans son travail", scores: {dominant: 0.5, stabilité: 0.6, conformité: 0.9, influent: 0.4}},
    {text: "Structurer une pensée critique", scores: {dominant: 0.2, stabilité: 0.6, conformité: 0.9, influent: 0.4}},
    {text: "Garder l'objectif en vue", scores: {dominant: 0.9, stabilité: 0.6, conformité: 0.4, influent: 0.1}},
    {text: "Travailler efficacement", scores: {dominant: 0.9, stabilité: 0.4, conformité: 0.3, influent: 0.1}},
    {text: "Proposer des solutions rationnelles et factuelles", scores: {dominant: 0.5, stabilité: 0.5, conformité: 0.9, influent: 0.3}},
    {text: "Respecter le cadre et le process", scores: {dominant: 0.4, stabilité: 0.7, conformité: 0.9, influent: 0.3}},
    {text: "Collecter des informations pertinentes", scores: {dominant: 0.1, stabilité: 0.4, conformité: 0.9, influent: 0.3}},  ];