import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  WrapperLayout,
  AdminLayout,
  ActivationCheckBox,
} from "./PageListTab.style";
import PersonIcon from "@mui/icons-material/Person";

import {
  Box,
  Button,
  ButtonBase,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";
import { studentLocal } from "../../utils/userLocal";

import {
  UPDATE_USER_PIC,
  UPDATE_USER,
} from "../../utils/graphql/user/mutation";

import {
  GET_CURRENT_USER,
  GET_USER_BY_GROUP_ID,
} from "../../utils/graphql/user/query";
import { ADD_AWAYKER_IN_GROUP } from "../../utils/graphql/awaykerGroup/mutation";
import { REMOVE_AWAYKER_FROM_GROUP } from "../../utils/graphql/awaykerGroup/mutation";
import { GET_ALL_USERS } from "../signup/graphQl/query";
import { GET_GROUPS, GET_GROUP_BY_ID } from "../../utils/graphql/group/query";
import { GET_SCHOOLS } from "../../utils/graphql/school/query";
import { StyledButton } from "../../utils/StyledButton.style";
import crypto from "crypto-js";
import { UPDATE_GROUP } from "../../utils/graphql/group/mutation";
import { useMutation, useQuery, gql } from "@apollo/client";
import { tokenise } from "../../utils/tokenise";
import { decrypt } from "../../utils/decrypt";
import {
  GET_AWAYKER_GROUPS_BY_AWAYKER_ID,
  GET_AWAYKER_GROUPS_BY_GROUP_ID,
} from "../../utils/graphql/awaykerGroup/query";

const path = require("path");
export const PageListTab = ({ propsDevice }) => {
  /*   let { idUser, idGroup, name } = useParams(); */

  let { idCrypted } = useParams();
  var originalText = decrypt(idCrypted);

  const idUser = parseInt(originalText[0]);
  const idGroup = parseInt(originalText[1]);
  const name = originalText[2];

  const [openDialog, setOpenDialog] = useState(false);
  /*   const [valueSchool, setValueSchool] = useState(false);
  const [valueGroup, setValueGroup] = useState(false); */
  const [valueText, setValueText] = useState(false);
  const [valueRole, setValueRole] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [oldPersonName, setoldPersonName] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [newActivatedModules, setNewActivatedModules] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const [currentUser, setCurrentuser] = useState();
  const roleList = [
    { id: 1, name: "Admin" },
    { id: 2, name: "Teacher" },
    { id: 3, name: "Student" },
    { id: 4, name: "Awayker" },
  ];

  const modulesList = [
    { id: 1, name: "DISC", label: "Activer le module DISC" },
    {
      id: 2,
      name: "INTMUL",
      label: "Activer le module INTELLIGENCES MULTIPLES",
    },
    {
      id: 3,
      name: "VALMOT",
      label: "Activer le module VALEURS ET MOTIVATIONS",
    },
  ];

  const {
    client,
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    data: dataCurrentUser,
  } = useQuery(GET_CURRENT_USER);

  const {
    loading: loadingAllUsers,
    error: errorAllUsers,
    data: dataAllUsers,
  } = useQuery(GET_ALL_USERS);

  const {
    loading: loadingGroupStudents,
    error: errorGroupStudents,
    data: dataGroupStudents,
  } = useQuery(GET_USER_BY_GROUP_ID, {
    variables: {
      groupId: parseInt(idGroup),
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: loadingAwaykersGroups,
    error: errorAwaykersGroups,
    data: dataAwaykersGroups,
  } = useQuery(GET_AWAYKER_GROUPS_BY_GROUP_ID, {
    variables: {
      groupId: parseInt(idGroup),
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: loadingGroup,
    error: errorGroup,
    data: dataGroup,
  } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      getGroupByIdId: parseInt(idGroup),
    },
  });

  // Mutation pour retirer un awayker du groupe
  const [
    removeAwayker,
    { dataRemoveAwayker, loadingRemoveAwayker, errorRemoveAwayker },
  ] = useMutation(REMOVE_AWAYKER_FROM_GROUP, {
    refetchQueries: [
      {
        query: GET_AWAYKER_GROUPS_BY_GROUP_ID,
        variables: { groupId: parseInt(idGroup) },
      },
      {
        query: GET_USER_BY_GROUP_ID,
        variables: { groupId: parseInt(idGroup) },
      },
    ],
    update(cache, { data: { removeAwayker } }) {
      if (!removeAwayker) return; // Vérification supplémentaire pour éviter des erreurs

      try {
        // Lecture de l'état actuel du cache pour la requête GET_AWAYKER_GROUPS_BY_GROUP_ID
        const existingGroups = cache.readQuery({
          query: GET_AWAYKER_GROUPS_BY_GROUP_ID,
          variables: { groupId: parseInt(idGroup) },
        });

        if (existingGroups) {
          // Mise à jour du cache avec le awayker retiré
          cache.writeQuery({
            query: GET_AWAYKER_GROUPS_BY_GROUP_ID,
            data: {
              getAwaykerGroupsByGroupId:
                existingGroups.getAwaykerGroupsByGroupId.map((group) => {
                  if (group.id === parseInt(idGroup)) {
                    return {
                      ...group,
                      awaykerGroups: group.awaykerGroups.map(
                        (awaykerGroup) => ({
                          ...awaykerGroup,
                          user: awaykerGroup.user.filter(
                            (user) => user.id !== removeAwayker.id
                          ), // Filtrer l'awayker à retirer
                        })
                      ),
                    };
                  }
                  return group;
                }),
            },
          });
        }
      } catch (err) {
        console.error(
          "Erreur lors de la mise à jour du cache après removeAwayker:",
          err
        );
      }
    },
    onError: (err) => {
      console.error("Erreur lors de la mutation:", err);
    },
    errorPolicy: "all",
  });

  const [
    addAwaykerInGroup,
    { dataAddAwayker, loadingAddAwayker, errorAddAwayker },
  ] = useMutation(ADD_AWAYKER_IN_GROUP, {
    refetchQueries: [
      {
        query: GET_AWAYKER_GROUPS_BY_GROUP_ID,
        variables: { groupId: parseInt(idGroup) },
      },
      {
        query: GET_USER_BY_GROUP_ID,
        variables: { groupId: parseInt(idGroup) },
      },
    ],
    update(cache, { data: { addAwaykerInGroup } }) {
      const { idGroup, userId } = addAwaykerInGroup;

      try {
        // Lecture de l'état actuel du cache pour la requête GET_AWAYKER_GROUPS_BY_GROUP_ID
        const existingGroups = cache.readQuery({
          query: GET_AWAYKER_GROUPS_BY_GROUP_ID,
          variables: { groupId: parseInt(idGroup) },
        });

        if (existingGroups) {
          // Mise à jour du cache avec le nouvel awayker ajouté
          cache.writeQuery({
            query: GET_AWAYKER_GROUPS_BY_GROUP_ID,
            data: {
              getAwaykerGroupsByGroupId:
                existingGroups.getAwaykerGroupsByGroupId.map((group) => {
                  if (group.id === parseInt(idGroup)) {
                    return {
                      ...group,
                      awaykerGroups: group.awaykerGroups.map(
                        (awaykerGroup) => ({
                          ...awaykerGroup,
                          user: [
                            ...awaykerGroup.user,
                            { __typename: "User", id: userId },
                          ], // Ajoute le nouvel awayker
                        })
                      ),
                    };
                  }
                  return group;
                }),
            },
          });
        }
      } catch (err) {
        console.error(
          "Erreur lors de la mise à jour du cache après addAwaykerInGroup:",
          err
        );
      }
    },
    onError: (err) => {
      console.error("Erreur lors de la mutation:", err);
    },
    errorPolicy: "all",
  });

  const [
    updateGroup,
    { dataUpdateGroup, loadingUpdateGroup, errorUpdateGroup },
  ] = useMutation(UPDATE_GROUP, {
    refetchQueries: [
      { query: GET_GROUP_BY_ID, variables: { groupId: parseInt(idGroup) } },
    ],

    onError: (err) => {
      console.error("Mutation error:", err);
      alert("Erreur lors de la mise à jour du groupe"); // Pour une meilleure visibilité de l'erreur
    },
    update(cache, { data: { updateGroup } }) {
      const { id, activatedModules } = updateGroup;

      try {
        // Lecture de l'état actuel du cache pour la requête GET_GROUP_BY_ID
        const existingGroup = cache.readQuery({
          query: GET_GROUP_BY_ID,
          variables: { groupId: parseInt(idGroup) },
        });

        if (existingGroup) {
          // Utilisation du fragment pour éviter d'écrire toutes les données manuellement
          const updatedGroupRef = cache.writeFragment({
            data: updateGroup,
            fragment: gql`
              fragment UpdatedGroup on Group {
                id
                activatedModules
              }
            `,
          });

          // Mise à jour du cache avec les nouvelles valeurs
          cache.modify({
            fields: {
              getGroupById(existingGroupData = []) {
                return existingGroupData.map((group) =>
                  group.id === parseInt(id) ? updatedGroupRef : group
                );
              },
            },
          });
        }
      } catch (err) {
        console.error(
          "Erreur lors de la mise à jour du cache après updateGroup:",
          err
        );
      }
    },
    onError: (err) => {
      console.error("Erreur lors de la mutation:", err);
    },
    errorPolicy: "all",
  });

  const getUserId = (name) => {
    let extractId;
    if (name) {
      Object.values(dataAllUsers?.getUsers)?.map((item) => {
        if (item?.name + " " + item?.lastname === name) {
          extractId = item?.id;
        }
      });
    }
    return extractId;
  };

  const extractAssignedAwayker = () => {
    return (
      dataAwaykersGroups?.getAwaykerGroupsByGroupId.map(
        (item) => `${item?.user?.name} ${item?.user?.lastname}`
      ) || []
    );
  };

  useEffect(() => {
    async function fetchData() {
      if (dataAwaykersGroups) {
        const updatedArray = extractAssignedAwayker();
        setPersonName(updatedArray);
      }
      setIsInitialLoad(false);
    }

    fetchData();
  }, [dataAwaykersGroups]);

  const [
    updateUser,
    { dataUpdatedUser, loadingUpdatedUser, errorUpdatedUser },
  ] = useMutation(UPDATE_USER);

  const enviroment =
    process.env.APP_ENV === "development"
      ? "https://waykeup-dev.herokuapp.com/"
      : "https://www.waykeup.app/";

  useEffect(() => {
    if (dataCurrentUser) {
      setCurrentuser(dataCurrentUser?.getCurrentUser);
    }
  }, [dataCurrentUser]);

  const safeUrlFunction = (dynamicURL) => {
    const safeUrl = encodeURIComponent(dynamicURL);
    return safeUrl;
  };

  useEffect(() => {
    const safeUrl = safeUrlFunction(
      `${tokenise(
        `${valueRole}/${currentGroup?.school_id}/${currentGroup?.id}`
      )}`
    );

    setValueText(`${enviroment}signup/${safeUrl}`);
  }, [valueRole]);

  let navigate = useNavigate();
  const currentGroup = dataGroup?.getGroupById;

  const updateUserFunction = async (newRoleId, idUser) => {
    const data = await updateUser({
      variables: {
        userUpdateInput: {
          id: parseInt(idUser),
          roleId: newRoleId,
        },
      },
    });
  };

  const activateModule = async (item) => {
    if (isUpdating) return;
    setIsUpdating(true);
    const isModuleActivate = newActivatedModules.includes(item);

    const updatedModules = isModuleActivate
      ? newActivatedModules.filter((module) => module !== item)
      : [...newActivatedModules, item];

    // Met à jour l'état local immédiatement
    setNewActivatedModules(updatedModules);

    // Envoie les données mises à jour au backend
    await handleUpdateActivation(updatedModules);
    setIsUpdating(false);
  };

  useEffect(() => {
    if (currentGroup?.activatedModules) {
      setNewActivatedModules(currentGroup.activatedModules);
    }
  }, [currentGroup?.activatedModules]);

  /* const activateModule = async (item) => {
    const isModuleActivate = currentGroup?.activatedModules.includes(item);

    const updatedModules = isModuleActivate
      ? currentGroup?.activatedModules.filter((module) => module !== item)
      : [...currentGroup?.activatedModules, item];

    // Met à jour l'état de newActivatedModules immédiatement
    setNewActivatedModules(updatedModules);

    // Appelle la mutation avec la nouvelle liste d'activatedModules
    await handleUpdateActivation(updatedModules);
  };
 */
  const handleUpdateActivation = async (updatedModules) => {
    const groupUpdateInput = {
      id: parseInt(currentGroup?.id), // Assure-toi que l'ID est un nombre
      activatedModules: updatedModules, // Assure-toi que 'updatedModules' est un tableau
    };

    const data = await updateGroup({
      variables: {
        groupUpdateInput,
      },
    });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Prénom", width: 130 },
    { field: "lastname", headerName: "Nom", width: 130 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "created_at",
      headerName: "Profil ajouté le",
      width: 130,
      renderCell: (params) => {
        let newDate = new Date(params.row.created_at * 1);
        const day = newDate.getUTCDate();
        const month = newDate.getUTCMonth() + 1; // Les mois sont indexés de 0 à 11
        const year = newDate.getUTCFullYear();
        const hours = newDate.getUTCHours();
        const minutes = newDate.getUTCMinutes();

        const formattedDate = `${day}/${month}/${year} ${hours}h${minutes}`;

        return formattedDate;

        /*  const dateString = newDate.toUTCString();
        return dateString; */
      },
    },
    {
      field: "role",
      headerName: "Role",
      width: 200,
      renderCell: (params) => {
        return (
          <FormControl disabled={currentUser?.roleId !== 1 ?? true}>
            <Select
              labelId={
                currentUser?.roleId === 1
                  ? "demo-simple-select-autowidth-label"
                  : "demo-simple-select-disabled-label"
              }
              id={
                currentUser?.roleId === 1
                  ? "demo-simple-select-autowidth"
                  : "demo-simple-select-disabled"
              }
              value={params.row.roleId}
              onChange={(e) =>
                updateUserFunction(e.target.value, params.row.id)
              }
              label="Role"
              sx={{ width: "190px", margin: "0 10px 0 0" }}
            >
              {roleList.map((item) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 210,
      renderCell: (params) => {
        const onClickButton = (e) => {
          navigate(
            `/shared/${safeUrlFunction(
              tokenise(
                `${idUser}/${idGroup}/${params.row.id}/${params.row.name}`
              )
            )}`
          );
        };

        return params.row.profilVisible === true ? (
          <Button variant="outlined" onClick={() => onClickButton()}>
            Voir Profil
          </Button>
        ) : (
          <Button variant="outlined" disabled>
            Profil non visible
          </Button>
        );
      },
    },
  ];

  const rows = currentGroup?.students;

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChangeAwaykerChip = (event) => {
    const newPersonName = event.target.value;

    // Obtenir l'ancien état avant la mise à jour
    setoldPersonName(personName);

    // Met à jour l'état local
    setPersonName(newPersonName);
  };

  useEffect(() => {
    // Calculer les différences à partir du nouvel état
    const awaykersToRemove = oldPersonName.filter(
      (name) => !personName.includes(name)
    );
    const awaykersToAdd = personName.filter(
      (name) => !oldPersonName.includes(name)
    );

    if (!isInitialLoad) {
      handleAwaykersMutations(awaykersToRemove, awaykersToAdd);
    }
  }, [personName]);

  const handleAwaykersMutations = async (awaykersToRemove, awaykersToAdd) => {
    try {
      // Supprimer les awaykers
      for (const awayker of awaykersToRemove) {
        const userId = parseInt(getUserId(awayker));
        await removeAwayker({
          variables: { userId, groupId: parseInt(idGroup) },
        });
      }

      // Ajouter les nouveaux awaykers
      for (const awayker of awaykersToAdd) {
        const userId = parseInt(getUserId(awayker));
        await addAwaykerInGroup({
          variables: {
            awaykerGroupInput: {
              userId,
              groupId: parseInt(idGroup),
            },
          },
        });
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des awaykers :", error);
    }
    setoldPersonName(personName);
  };

  return (
    <WrapperLayout>
      <div
        style={{
          display: "flex",
          font: "normal normal bold 27px Helvetica Neue",
          color: "#316880",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <div>{name + " - " + currentGroup?.school?.name + " - "}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <PersonIcon />
          {currentGroup?.students?.length}
        </div>
      </div>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={rows ?? []}
          columns={columns ?? []}
          pageSize={15}
          rowsPerPageOptions={[15]}
          /* checkboxSelection */
        />
      </div>
      <StyledButton
        grota={true}
        colorText={"#326880"}
        backgroundColor={"transparent"}
        colorHover={"#FFF"}
        fontSize={"13px"}
        isBorder={true}
        borderColor={"#326880"}
        onClick={() => setOpenDialog(true)}
      >
        {"AJOUTER UN MEMBRE AU GROUPE"}
      </StyledButton>

      <div>
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogTitle>{`AJOUTER UN MEMBRE A CE GROUPE`}</DialogTitle>
          <DialogContent>
            <DialogContentText>{""}</DialogContentText>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <div id="demo-simple-select-autowidth-label">Établissement</div>
              <TextField size={"100%"} value={currentGroup?.school?.name} />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <div id="demo-simple-select-autowidth-label">Groupe</div>
              <TextField
                sx={{ width: "250px" }}
                size={"100%"}
                value={currentGroup?.name}
              />
            </FormControl>
            {currentUser?.roleId == 1 && currentGroup?.id == 1 ? (
              <>
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={valueRole}
                    onChange={(e) => setValueRole(e.target.value)}
                    label="Role"
                    sx={{ width: "220px", margin: "0 10px 0 0" }}
                  >
                    {roleList.map((item) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <div>
                  {
                    "Copier ce lien pour qu'un membre créé ses identifiants et s'inscrive :"
                  }
                </div>
                <TextField fullWidth value={valueText} id="fullWidth" />
              </>
            ) : (
              <>
                <div>
                  {
                    "Copier ce lien pour qu'un membre créé ses identifiants et s'inscrive :"
                  }
                </div>
                <TextField
                  fullWidth
                  value={`${enviroment}signup/${safeUrlFunction(
                    tokenise(`3/${currentGroup?.school_id}/${currentGroup?.id}`)
                  )}`}
                  id="fullWidth"
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  currentUser?.roleId == 1 && currentGroup?.id == 1
                    ? valueText
                    : `${enviroment}signup/${safeUrlFunction(
                        tokenise(
                          `3/${currentGroup?.school_id}/${currentGroup?.id}`
                        )
                      )}`
                );
                handleClose();
              }}
            >
              Copier le lien dans le press-papier
            </Button>
          </DialogActions>
        </Dialog>

        {/*   ##### AJOUTER AWAYKER SI ADAMIN et ACTIVATION DE MODULES ########### */}
        {currentUser?.roleId === 1 && dataAllUsers?.getUsers?.length >= 0 ? (
          <AdminLayout>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">Awaykers</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={personName}
                onChange={handleChangeAwaykerChip}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Awaykers" />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected?.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 30,
                      width: 260,
                    },
                  },
                }}
              >
                {Object.values(dataAllUsers?.getUsers)?.map((item) => {
                  if (item?.roleId === 4 || item?.roleId === 1) {
                    return (
                      <MenuItem
                        key={item?.id}
                        value={item?.name + " " + item?.lastname}
                      >
                        {item?.name + " " + item?.lastname}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
            <div>
              {modulesList.map((module) => (
                <ActivationCheckBox key={module.id}>
                  <Checkbox
                    checked={newActivatedModules.includes(module.name)}
                    sx={{
                      color: "#5BC5F2",
                      "&.Mui-checked": {
                        color: "#5BC5F2",
                      },
                    }}
                    onChange={() => activateModule(module.name)}
                  />
                  <div>{module.label}</div>
                </ActivationCheckBox>
              ))}
            </div>
          </AdminLayout>
        ) : (
          <></>
        )}
      </div>
    </WrapperLayout>
  );
};
