import { gql } from "@apollo/client";

export const CREATE_GROUP = gql`
mutation CreateGroup($groupInput: GroupInput!) {
    createGroup(groupInput: $groupInput) {
      id
      name
      year
    }
  }`




export const DELETE_GROUP = gql`
mutation DeleteGroup($deleteGroupId: ID!) {
  deleteGroup(id: $deleteGroupId) {
    id
  }
}
`;

export const UPDATE_GROUP = gql`
mutation UpdateGroup($groupUpdateInput: GroupUpdateInput!) {
  updateGroup(groupUpdateInput: $groupUpdateInput) {
    id
    name
    year
    size
    activatedModules
  }
}`;